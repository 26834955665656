.main_reply {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-direction: column;
    background-color: #f0f6f5;
    padding-bottom: 20px;


  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #201E1F !important;
    }

    .ant-tabs .ant-tabs-ink-bar {
        background: #201E1F;

    }

    .ant-tabs-tab {
        color: #9cb4b0 !important;
    }


    .swipe_tips {
        display: none;

        @media(max-width:1000px) {
            display: flex;
            padding: 130px 30px 30px 30px;
        }
    }

    .slick-prev,.slick-next{
    display: none !important;
    }

    .slick-slider {
        width: 700px;

        @media(max-width:750px) {
            width: 500px;
        }

        @media(max-width:600px) {
            width: 400px;
        }

        @media(max-width:600px) {
            width: 300px;
        }

        .para {

            padding-bottom: 40px;
            font-size: 21px;
            font-family: "PT Sans", sans-serif;
            font-weight: 200;
            color: black;
            max-width: 700px;

            @media(max-width:700px) {
                font-size: 18px;
                padding-bottom: 20px;
            }

            @media(max-width:750px) {
                max-width: 500px;
            }
        }

    }

    .main_reply_tab {
        .action {
            font-size: 18px;
            text-decoration: underline;
            font-family: "PT Sans", sans-serif;
            font-weight: 600;
            color: #9cb4b0;
            cursor: pointer;

            @media(max-width:700px) {
                font-size: 14px;
            }


        }

        .title {
            font-size: 22px;
            font-family: "PT Sans", sans-serif;
            font-weight: 600;
            color: #201E1F;

            @media(max-width:700px) {
                font-size: 14px;
                display: flex;
                gap: 2rem;
                align-items: center;
                align-items: center;
            }

            .tip {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 25px 20px;
                background-color: white !important;
                border-top-left-radius: 40px;
                cursor: pointer;
                border-bottom-left-radius: 40px;
                right: 0;
                top: 80px;
                height: 40px;
                font-weight: 300 !important;
                font-size: 21px;
                color: #9cb4b0;

                // @media(max-width:1000px) {
                //     display: none;
                // }
            }


        }

        .para {
            padding-top: 30px;
            padding-bottom: 40px;
            font-size: 21px;
            font-family: "PT Sans", sans-serif;
            font-weight: 200;
            color: black;
            max-width: 500px;

            @media(max-width:700px) {
                font-size: 18px;
                padding-bottom: 20px;
            }
            @media(max-width:570px) {
              width: 100%;
              max-width: unset;  
              padding-top: 10px;
              padding-bottom: 10px;
            }

            textarea {
                width: 500px;
                height: 200px;
                resize: none;
                border: none;
                outline: none;
                font-size: 21px;
                font-family: "PT Sans", sans-serif;
                font-weight: 200;
                color: black;
                padding: 20px;
                border-radius: 15px;
                @media(max-width:700px) {
                    font-size: 18px;
                }
                @media(max-width:570px) {
                    width: 400px; 
                  }
                @media(max-width:470px) {
                    width: 300px; 
                  }
                @media(max-width:370px) {
                    width: 250px; 
                  }
            }

        }


    }

    .reply_content,
    .reply_for_padding {
        padding-top: 80px;
        transition: .3s ease-in-out;

        @media(max-width:1000px) {
            display: none;
        }

        .title {
            font-size: 22px;
            font-family: "PT Sans", sans-serif;
            font-weight: 600;
            color: #201E1F;

            @media(max-width:700px) {
                font-size: 14px;
                display: flex;
                gap: 2rem;
                align-items: center;
                align-items: center;
            }

            .tip {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 25px 20px;
                background-color: white !important;
                border-top-left-radius: 40px;
                cursor: pointer;
                border-bottom-left-radius: 40px;
                right: 0;
                top: 80px;
                height: 40px;
                font-weight: 300 !important;
                font-size: 21px;
                color: #9cb4b0;

                // @media(max-width:1000px) {
                //     display: none;
                // }
            }


        }

        .para {
            padding-top: 30px;
            padding-bottom: 40px;
            font-size: 21px;
            font-family: "PT Sans", sans-serif;
            font-weight: 200;
            color: black;
            max-width: 500px;

            @media(max-width:700px) {
                font-size: 18px;
                padding-bottom: 10px;
                padding-top: 10px;
            }

            textarea {
                width: 500px;
                height: 200px;
                resize: none;
                border: none;
                outline: none;
                font-size: 21px;
                font-family: "PT Sans", sans-serif;
                font-weight: 200;
                color: black;
                padding: 20px;
                padding-bottom: 30px;
                border-radius: 15px;
            }

        }

        .action {
            font-size: 16px;
            text-decoration: none;
            font-family: "PT Sans", sans-serif;
            font-weight: 400;
            color: #9cb4b0;
            cursor: pointer;
            margin-top: -40px;
            margin-bottom: 40px;
            margin-left: 20px;
            position: relative;
            bottom: 0px;
            left: 10px;

            @media(max-width:700px) {
                font-size: 14px;
            }


        }
    }


    .btn_rply {
        background-color: transparent !important;
        width: 100%;
        margin-top: 20px;
        padding-right: 100px;

        @media(max-width:1000px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-right: unset;
        }

        .button {
            font-size: 21px;
            width: 250px;
            background-color: #e2521e;
            font-size: 21px;
            padding: 10px 50px;
            margin-top: 60px;

            @media(max-width:1000px) {
                font-size: 18px;
                width: 200px;
            }
            @media(max-width:768px) {
                padding: 10px 30px;
                font-size: 14px;
                margin-top: 10px;
            }
        }
        
        .disabled {
            opacity: 0.5;
        }

        .text {
            font-family: "PT Sans", sans-serif;
            font-size: 16px;
            color: #201E1F;
            text-decoration: underline;
            cursor: pointer;
            display: none;

            @media(max-width:1000px) {
                display: flex;
                padding-top: 20px;
            }
        }

        .save_it {
            margin-top: 20px;
           
            .buttonNotSaved {
                display: flex;
                align-items: center;
                background-color: white;
                color: black;
                font-size: 21px;
                padding: 10px 30px;
                border-radius: 30px;
                font-weight: 700;

                @media(max-width:1000px) {
                    font-size: 18px;
                    width: 200px;
                }
            }
            .buttonSaved {
                display: flex;
                align-items: center;
                background-color: #201E1F;
                color: white;
                font-size: 21px;
                padding: 10px 30px;
                border-radius: 30px;
                font-weight: 700;

                @media(max-width:1000px) {
                    font-size: 18px;
                    width: 200px;
                }
            }
        }

    }
}

.reply_for_padding {
    padding-right: 50%;


    @media(max-width:1160px) {
        padding-right: unset;
    }
}

.ant-drawer-bottom {

    .ant-drawer-header {
        display: none !important;
    }

    .ant-drawer-content-wrapper {
        height: unset !important;

    }

    .ant-drawer-content-wrapper {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .ant-drawer-content {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .ant-drawer-body {
        .valuate_content {

            .subhead {
                font-size: 14px;
                font-family: "PT Sans", sans-serif;
                color: #201E1F;

                @media(max-width:1000px) {
                    font-size: 14px;
                }
            }

            .title {
                font-size: 26px;
                font-weight: 600;
                font-family: "PT Sans", sans-serif;
                color: #201E1F;
                padding-top: 10px;

                @media(max-width:1000px) {
                    font-size: 21px;
                }
            }

            .para {
                font-size: 21px;
                font-weight: 100;
                font-family: "PT Sans", sans-serif;
                color: #201E1F;
                max-width: 900px;
                padding-top: 10px;

                @media(max-width:1000px) {
                    font-size: 18px;
                }
            }

            .subhead_sec {
                font-size: 13px;
                font-weight: 100;
                font-family: "PT Sans", sans-serif;
                color: #201E1F;
                padding-top: 20px;
            }

            .icon {
                position: absolute;
                top: 20px;
                right: 20px;
                font-size: 30px;
                color: #4d4d4d;
                cursor: pointer;
            }
        }

    }
}


.ant-drawer-right {
    .ant-drawer-header {
        display: none !important;
    }

    .ant-drawer-content-wrapper {
        height: fit-content !important;
        width: 50% !important;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        margin-top: 100px;
    }

    .ant-drawer-content {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }

    .ant-drawer-body {
        padding: 24px 10px 24px 0px;

        .tips_content {
            padding: 15px 25px;

            .cross {
                font-size: 25px;
                display: flex;
                justify-content: end;
                align-items: end;
                position: absolute;
                right: 20px;
                top: 20px;
            }

            .para_main {
                padding-top: 20px;

                .para {
                    color: black;
                    font-family: "PT Sans", sans-serif;
                    font-size: 21px;

                    @media(max-width:1300px) {
                        font-size: 16px;
                    }
                }
            }

            .next_btn {
                display: flex;
                gap: 10px;
                font-size: 18px;
                padding-top: 20px;

                .icon {
                    color: #201E1F;
                    font-size: 25px;
                    border: 1px solid #201E1F;
                    border-radius: 50%;
                    cursor: pointer;

                    polyline {
                        stroke: #201E1F !important;
                    }
                }
            }
        }
    }
}