.main_help {
    background-color: #f0f6f5;
    height: 100vh;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;

    .main_boxshadow {
        width: 100%;
        height: 100vh;
        position: absolute;
        background-color: #f0f6f5;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: .3s ease-in-out;
        z-index: 111;
        // box-shadow: 0 0 0 50vmax rgba(0, 0, 0, .5);
        justify-content: center;
        align-items: center;

        .main_send {
            background-color: #f0f6f5;
            transition: .3s ease-in-out;
            width: 100%;
            display: flex;
            justify-content: center;


            .message_content {
                width: 650px;
                background-color: white;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: fit-content;
                padding-bottom: 50px;
                padding-top: 50px;
                position: relative;

                @media(max-width:700px) {
                    width: 90%;
                }

                .close {
                    font-size: 25px;
                    color: #4D4D4D;
                    position: absolute;
                    top: 25px;
                    cursor: pointer;
                    right: 25px;
                }

                .message_material {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: start;

                    .icon {
                        font-size: 80px;
                        color: #201E1F;
                    }

                    .title {
                        
                        font-size: 26px;
                        color: #201E1F;
                        padding-bottom: 20px;
                        max-width: 300px;
                        opacity: 0.9;
                        text-align: center;

                        @media(max-width:700px) {
                            font-size: 18px;
                        }
                    }

                    .feed_form {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                    }

                    textarea {
                        border: none;
                        padding-left: 10px;
                        background-color: #F0F6F5;
                        border-radius: 10px;
                        outline: none;
                        padding-top: 10px;
                        resize: none;

                        &::placeholder {
                            color: #201E1F;
                            
                            font-size: 13px;
                        }
                    }

                    .para {
                        max-width: 195px;
                        opacity: 0.9;
                        font-weight: 100;
                        padding-top: 20px;
                        
                        font-size: 18px;
                        color: #201E1F;
                        text-align: center;
                    }
                }

            }

        }
    }


    .help_content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 120px;
        align-items: center;

        .title {
            
            font-size: 34px;
            color: #201E1F;
            padding-bottom: 20px;
            text-align: center;
            font-weight: 600;

            @media(max-width:600px) {
                font-size: 26px;

            }
        }


        .card {
            display: flex;
            background-color: white;
            padding: 20px 20px 20px 20px;
            border-radius: 10px;
            gap: 1rem;
            width: 250px;
            cursor: pointer;

            .icon {
                font-size: 26px;
                color: #4D4D4D;
            }

            .text {
                
                font-size: 18px;
                color: #201E1F;
                font-weight: 600;
            }
        }

    }
}