.generated-topics-container{
    display: flex;
    justify-content: center;
    color: #201E1F;
    font-family: var(--content-font) !important;
    font-weight: 400;
}
.generated-topics-wrapper{
    width: 900px;
    margin-top: 25px;
}
.generated-topics-logo{
    text-align: center;
    padding-top: 0px;
    padding-bottom: 50px;
    cursor: pointer;
}
.generated-topics-logo img{
    height: 20px;
    width: auto;
}
.gen-topics-heading{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.gen-topics-heading-left > h2{
    font-size: 30px;
    font-family: var(--title-font) !important;
    font-weight: 600;
    letter-spacing: 0.5px;
    width: 460px;
    line-height: 35px;
}
.gen-topics-heading-left > h2 > span{
    font-size: 30px;
    font-family: var(--title-font) !important;
    font-weight: 600;
    letter-spacing: 0.5px;
    width: 460px;
    line-height: 35px;
    color: #F18F01;
}
.gen-topics-heading-left p{
    font-size: 18px;
    margin-top: 10px;
}
.gen-topics-heading-right{
    margin-left: 60px;
}
.gen-topics-heading-right button{
    background-color: #F18F01;
    color: #fff;
    padding: 13px 37px;
    border-radius: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
}
.gen-topics-heading-right button h6{
    color: #fff;
    font-family: var(--title-font) !important;
    font-weight: 600;
    font-size: 23px;
    letter-spacing: 0px;
}
.gen-topics-heading-right button p{
    color: #fff;
    font-family: var(--content-font) !important;
    font-weight: 400;
    font-size: 16px;
    margin-top: 5px;
}
.gen-topics-card-list{
    margin-top: 60px;
    display: flex;
    align-items: center;
    width: 100%;
}
.gen-topics-card{
    // margin-right: 40px;
    width: 200px;
    position: relative;
    height: 190px;
    background-color: #fff;
    border-radius: 20px;
    // cursor: pointer;
}
.gen-topics-card svg{ 
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.gen-topics-card-active{
    opacity: 1;
    // pointer-events: auto;
    // cursor: pointer;
}
.gen-topics-card-inactive{
    opacity: 0.5;
    // pointer-events: none;
    // cursor: none;
}
.gen-topics-lock{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 28px;
    height: auto;
    margin-left: -14px;
    margin-top: -16px;
    cursor: pointer;
}
.gen-topics-lock-active{
    display: block;
    opacity: 1;
}
.gen-topics-lock-inactive{
    display: none;
}
.gen-topics-card-text{
    color: #000;
    padding: 20px;
    font-size: 20px;
    position: absolute;
    z-index: 9;
    // top: 20px;
    left: 0px;
    right: 0px;
}
.genTopicsCardColor{
    background: #fdf233;
    width: 100%;
    height: 20px;
    border-radius: 20px;
    // top: ;
}
.gen-topics-description{
    margin-top: 20px;
    width: 400px;
    font-size: 17px;
    font-style: italic;
}

// .gen-topics-card-color-bar {
//     background: #fdf233;
//     width: 100%;
//     height: 20px;
//     border-radius: 20px;
//     position: relative;
//     // bottom: 0%;
//     top: 188px;
//   }
.gen-topics-card-list-responsive-inactive{
    display: block;
}
// .swiper {
//     width: 100%;
//     height: 420px;
//     margin-left: auto;
//     margin-right: auto;
// }
// .swiper-slide {
    // text-align: center;
    // font-size: 18px;
    // height: calc((100% - 30px) / 2) !important;
    // display: flex;
    // justify-content: center;
    // align-items: center;
// }
.gen-topics-card-list-responsive-active{
    display: none;
}
.gen-topics-desktop-swiper{
    width: 100%;
}

.gen-topics-card-new{
    width: 90%;
    position: relative;
    height: 150px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: var(--global-box-shadow);
}
.gen-topics-card-new h6{
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0px;
    min-height: 65px;
}
.gen-topics-card-new p{
    font-family: var(--content-font) !important;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
}
.gen-topics-progress-bar{
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 20px;
    background-color: #FFDB5E;
    border-radius: 5px;
    width: 100%;
}

@media only screen and (max-width: 1000px) and (min-width: 700px){
    .gen-topics-card-list{
        width: 70vw;
        margin-left: auto;
        margin-right: auto;
    }
}
@media only screen and (max-width: 700px) and (min-width: 500px){
    .gen-topics-card-list{
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }
}


@media screen and (max-width: 1000px){
    .generated-topics-wrapper{
        width: 85vw;
    }
    .gen-topics-heading{
        flex-direction: column;
        align-items: center;
    }
    .gen-topics-heading-left > h2{
        text-align: center;
        font-size: 21px;
        width: 305px;
    }
    .gen-topics-heading-left > h2 > span{
        font-size: 21px;
    }
    .gen-topics-heading-right {
        margin-left: 0px;
        margin-top: 20px;
    }
    .gen-topics-description {
        width: 100%;
        text-align: center;
        padding-bottom: 100px;
    }
    .gen-topics-card-list-responsive-inactive{
        display: none;
    }
    .gen-topics-card-list-responsive-active{
        display: block;
    }
    // .gen-topics-card-new{
    //     width: 100%;
    // }
    .gen-topics-heading-right button{
        padding: 13px 28px;
    }
    .gen-topics-heading-right button h6{
        font-size: 21px;
    }
    .generated-topics-logo{
        padding-bottom: 35px;
    }
    .gen-topics-card-list {
        margin-top: 55px;
    }
}

@media screen and (max-width: 400px){
    .gen-topics-card-new h6{
        font-size: 14px;
    }
    // .gen-topics-card-new{
    //     width: 80%;
    //     margin-left: auto;
    //     margin-right: auto;
    // }
}





























        // .swiper {
        //     width: 100%;
        //     height: 440px;
        //     margin-left: auto;
        //     margin-right: auto;
        // }
        // .swiper-wrapper {
        //     height: 410px !important;
        // }
        // .swiper-slide {
        //     text-align: center;
        //     font-size: 18px;
        //     height: calc((100% - 30px) / 2) !important;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        // }
        // .mobile-main-swiper{
        //     width: 100%;
        // }
        // .gen-topics-card{
        //     width: 100%;
        //     height: 170px;
        // }
        // .gen-topics-card-list {
        //     margin-top: 40px;
        // }