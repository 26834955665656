.main_feed {
    display: flex;
    background-color: #f0f6f5;
    gap: 7rem;
    padding: 150px 0px 50px 130px;
   
    @media(max-width:1100px) {
        padding: unset;
        justify-content: center;
     padding-top: 150px;
    }
    

    @media(max-width:850px) {
        gap: 3rem;
        padding-top: 100px;
    }
    @media(max-width:930px) {
        gap: 3rem;
    }

    @media(max-width:850px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        padding-bottom: 50px;
    }

    .feed_text {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        @media(max-width:850px) {
          gap: 1rem;
         }
        .feed_title {
            
            font-size: 48px;
            color: #201E1F;
            @media(max-width:850px) {
               font-size: 26px;
               line-height: unset !important;
            }
        }

        .feed_para {
            
            font-size: 21px;
            color: #201E1F;
            @media(max-width:850px) {
                font-size: 14px;
             }
        }

    }

    .feed_card {
        display: flex;
        flex-direction: column;
        background-color: white;
        gap: 2rem;
        width: fit-content;
        padding: 50px 30px 50px 30px;
        height: fit-content;
        justify-content: center;
        border-radius: 10px;
        align-items: center;
        @media(max-width:850px) {
            padding: 20px 20px 20px 20px;
          }
        .feed_form {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .may {
            
            font-size: 18px;
            color: #201E1F;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
        }
    }

}