@property --progress-value {
  syntax: "<integer>";
  inherits: false;
  initial-value: 0;
}

$progress-value: 0; // Define the custom property as a variable

@keyframes html-progress {
  to {
    --progress-value: 100;
  }
}

.main_login {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--pearl-white);
  gap: 30px;
  @media (max-width: 600px) {
    gap: 20px;
  }

  .progress-bar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
    /* to center the percentage value */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-parent {
    text-align: center;
    line-height: 2;
    margin-top: 30px;
    font-size: 18px;
  }

  .html {
    background: radial-gradient(closest-side, #f0f6f5 79%, transparent 80% 100%),
      conic-gradient(#f18f01 calc(var(--progress-value) * 1%), white 0);
    // animation: html-progress 5s 1 forwards;
  }

  h2 {
    text-align: center;
  }

  progress {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}
