.main_library {
  font-family: "product-sans-regular-400", sans-serif !important;
  // padding-bottom: 150px;
  color: #201e1f;
  display: flex;
  justify-content: center;

  .library_content {
    width: 1000px;
    .librar_topsec {
      display: flex;
      justify-content: space-between;
      padding-top: 25px;


      @media (max-width: 500px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 25px;
        font-size: 30px !important;
      }

      .left_sec {
        // padding-left: 6.3%;
        display: flex;
        gap: 1rem;

        .back {
          background-color: gainsboro;
          color: #4d4d4d;
          width: 40px;
          height: 40px;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          position: absolute;
          left: 50px;
          cursor: pointer;
          @media (max-width: 750px) {
            left: 10px;
          }
        }

        .title {
          display: flex;
          gap: 1rem;
          align-items: center;
          font-size: 30px;
          color: #201E1F;
          font-family: var(--sub-title-font) !important;
          font-weight: 500;
          letter-spacing: 0px;

          @media (max-width: 500px) {
            flex-direction: column;
            align-items: center;
          }

          span {
            font-size: 21px;
            color: #201E1F;
            font-weight: 100;

            @media (max-width: 850px) {
              font-size: 18px;
            }
          }
        }
      }

      .right_sec {
        display: flex;
        // gap: 2rem;

        @media (max-width: 1000px) {
          gap: 1rem;
        }

        @media (max-width: 500px) {
          justify-content: space-between;
          width: 90%;
          padding-top: 20px;
        }

        .mixer_btn {
          display: flex;
          flex-direction: column;
          padding: 13px 20px;
          background-color: white;
          border-top-left-radius: 40px;
          cursor: pointer;
          border-bottom-left-radius: 40px;
          font-size: 20px;

          @media (max-width: 600px) {
            height: 45px;
          }

          @media (max-width: 500px) {
            background-color: unset;
          }
        }

        .btn {
          cursor: pointer;
          font-size: 18px;

          color: #201E1F;
          background-color: white;
          width: 150px;
          border: none;
          text-align: center;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 100;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;

          @media (max-width: 1250px) {
            width: 100px !important;
            font-size: 14px;
          }

          @media (max-width: 900px) {
            display: none;
          }
        }

        .search {
          display: flex;
          background-color: white;
          padding-left: 10px;
          width: 270px;
          font-size: 18px;

          align-items: center;
          justify-content: center;
          border-radius: 20px;
          gap: 15px;

          @media (max-width: 1250px) {
            width: 240px !important;
            font-size: 14px;
          }
          // @media (max-width: 500px) {
          //   width: 90% !important;
          // }
          @media (max-width: 500px) {
            width: 70% !important;
            height: 30px;
          }

          .icon {
            background-color: #ffffff;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            @media (max-width: 400px) {
              width: 23px;
              height: 20px;
            }
            svg {
              width: auto;
              height: 15px;
            }
          }

          input {
            width: 90%;
            font-size: 18px;

            color: #201E1F;
            border: none;
            outline: none;

            @media (max-width: 600px) {
              font-size: 13px;
            }

            &::placeholder {
              font-style: italic;
              font-size: 18px;

              color: #201E1F;

              @media (max-width: 600px) {
                font-size: 13px;
              }
            }
          }
        }
      }
    }

  }
}

@media screen and (max-width: 500px) {
  .search {
    position: absolute;
    z-index: 50;
    top: 17px;
    right: 10px;
  }
}

@media (max-width: 400px) {
  .qus-layer {
    display: flex;
    gap: 15px;
    padding: 0px 10px;
    align-items: center;
    .icon {
      img {
        width: auto;
        height: 20px;
      }
    }
  }

  // @media (max-width: 1250px) {
  //   gap: 1rem;
  // }
}

@media (min-width: 500px) {
  .qus-layer {
    .icon {
      display: none;
    }
  }

  .concept-responsive-context-menu-container {
    display: none;
  }
}

.concept-responsive-context-menu-container-none {
  display: none;
}

.concept-responsive-context-menu {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  // width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.concept-responsive-context-menu button {
  background-color: #27345e;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 13px;
  border-radius: 50px;
  margin-right: 10px;
}
.concept-responsive-context-menu img {
  width: auto;
  height: 15px;
  margin-left: auto;
}


.question-list-container{
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}
.question-title{
  width: 70%;
}
.question-title p{
  font-size: 20px;
  color: #000;
  font-weight: 400;
}
.question-card-wrapper{
  border-bottom: 1px solid #d3d3d3;
  position: relative;
}
.question-card{
  display: flex;
  align-items: center;
  padding: 20px 0px;
}
.question-menu{
  margin-left: auto;
  display: flex;
}
.question-btn{
  background-color: #ffffff00;
  font-size: 16px;
  font-weight: 600;
}
.answer-container{
  display: none;
}
.answer-content{
  background-color: #27345E;
  color: #fff;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 5px;
  display: flex;
  position: relative;
  align-items: center;
}
.answer-text {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  width: 70%;
}
.answer-close-icon{
  width: auto;
  height: 17px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 20px;
}
.question-mobile-menu-icon{
  display: none;
  // display: block;
  width: auto;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
}
.mobile-menu-container{
  display: none;
}
.mobile-menu-content{
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

}
.mobile-menu-content button{
  background-color: #27345E;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 13px;
  border-radius: 50px;
  margin-right: 10px;
}
.mobile-menu-content img{
  width: auto;
  height: 15px;
  margin-left: auto;
}


.library-empty-state-container{
  display: flex;
  // align-items: center;
  justify-content: center;
  width: 100%;
  margin: 100px auto 0px auto;
}
.library-empty-state-content{
  background-color: #fff;
  padding: 20px;
  width: 500px;
  min-height: 250px;
  border-radius: 20px;
  box-shadow: var(--global-box-shadow);
}
.library-empty-state-upload-file{
  text-align: center;
}
.library-empty-state-upload-file > p{
  font-size: 22px;
  font-weight: 400;
  margin-top: 25px;
}
// .library-empty-state-upload-file > button{
//   font-size: 24px;
//   font-weight: 400;
// }
.library-empty-state-upload-file > button{
  margin-top: 35px;
  background-color: #F18F01;
  padding: 10px 20px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
}
.library-empty-state-upload-file > button > img{
  width: auto;
  height: 25px;
  margin-right: 15px;
}
.library-empty-state-generate{
  text-align: center;
}
.library-empty-state-generate button{
  margin-top: 25px;
  background-color: #F18F01;
  padding: 10px 20px;
  font-size: 25px;
  color: #fff;
  border-radius: 20px;
}
.library-empty-state-generate p{
  font-size: 24px;
  font-weight: 400;
  margin-top: 20px;
}

@media only screen and (max-width: 1450px) and (min-width: 900px)  {
  .main_library{
    margin-left: 220px;
    justify-content: flex-start;
  }
  .library_content {
    width: 70vw !important;
  }
}
@media only screen and (max-width: 1000px) and (min-width: 500px)  {
  .main_library{
    margin-left: 0px;
    justify-content: center;
  }
  .library_content {
    width: 85vw !important;
  }
}



@media screen and (max-width: 500px) {
  .question-list-container{
    margin-top: 20px;
  }
  .library_content{
    width: 85vw !important;
  }
  .question-card-wrapper{
    border-bottom: 0px;
  }
  .question-card{
    background-color: #fff;
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
  }
  .question-menu{
    display: none;
  }
  .question-mobile-menu-icon{
    display: block;
  }
  .question-title{
    width: 90%;
  }
  .answer-text {
    width: 90%;
  }
  .answer-content{
    padding: 10px;
    margin-bottom: 25px;
    margin-top: -13px;
  }
  .answer-close-icon{
    margin-right: 0px;
  }
}