.main_noteadd {
  background-color: #f0f6f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .note_content {
    display: flex;
    padding: 150px 0px 50px 110px;
    gap: 7rem;

    @media (max-width: 990px) {
      padding: 150px 0px 50px 0px;
      width: 100%;
      justify-content: center;
    }

    .left_note {
      display: flex;
      flex-direction: column;

      @media (max-width: 990px) {
        width: 85%;
      }

      .top_sec {
        @media (max-width: 990px) {
          width: 100%;
        }

        .note_title {
          font-family: "PT Sans", sans-serif;
          font-size: 26px;
          color: #201E1F;
          padding-bottom: 20px;
        }

        .top_sec_content {
          display: flex;
          gap: 2rem;
          flex-wrap: wrap;

          @media (max-width: 990px) {
            flex-wrap: nowrap;
            flex-direction: column;
          }

          .add_more_card {
            display: flex;
            gap: 1.5rem;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            height: 221px;
            background-color: white;
            width: 315px;
            border-radius: 10px;
            justify-content: center;
            height: 315px;

            @media (max-width: 990px) {
              width: 100%;
            }

            .plus {
              background-color: #201E1F;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              .icon {
                font-size: 20px;
                color: white;
                font-weight: 900;
              }
            }

            .add_text {
              font-family: "PT Sans", sans-serif;
              font-size: 18px;
              color: #201E1F;
            }
          }

          .note_card {
            display: flex;
            flex-direction: column;
            background-color: white;
            min-width: 320px;
            padding: 20px 20px 20px 20px;
            gap: 1rem;
            border-radius: 10px;
            width: fit-content;
            align-items: start;
            position: relative;

            @media (max-width: 990px) {
              width: 100%;
            }

            input {
              width: 256px;
              height: 35px;
              background-color: #f0f6f5;
              outline: none;
              border: none;
              border-radius: 10px;
              padding-left: 10px;

              @media (max-width: 990px) {
                width: 90%;
              }
              @media (max-width: 768px) {
                width: 100%;
              }

              &::placeholder {
                color: #201E1F;
                font-family: "PT Sans", sans-serif;
                font-size: 13px;
              }
            }

            .note_group {
              display: flex;
              align-items: center;
              gap: 1rem;
              flex-direction: column;
              width: fit-content;
              position: relative;

              @media (max-width: 990px) {
                width: 100%;
                justify-content: space-between;
              }
              .text-area {
                width: 100%;
                height: 100%;
              }
              textarea {
                border: none;
                padding-left: 10px;
                background-color: #f0f6f5;
                border-radius: 10px;
                outline: none;
                padding: 15px;
                resize: none;
                width: 100%;
                overflow-y: auto;

                @media (max-width: 990px) {
                  width: 90%;
                }

                @media (max-width: 768px) {
                  width: 100%;
                }

                &::placeholder {
                  color: #201E1F;
                  font-family: "PT Sans", sans-serif;
                  font-size: 13px;
                }
              }

              .expand_icon {
                position: absolute;
                bottom: 5px;
                right: 5px;
              }
              .icon {
                font-size: 25px;
                color: #4d4d4d;
                cursor: pointer;
                position: absolute;
                right: 10px;

                @media (max-width: 500px) {
                  right: 10px;
                }
              }
            }
            .note_footer {
              display: flex;
              align-items: center;
              justify-content: center;

              .save_btn {
                background-color: #201E1F;
                color: white;
                padding: 8px 15px 10px 15px;
                border: none;
                cursor: pointer;
                border-radius: 30px;
                font-weight: 700;
                display: flex;
                width: 100px;
                justify-content: center;
                align-items: center;
              }

              .del_btn {
                color: #201E1F;
                margin-left: 10px;
              }
            }
          }
        }

        .link_main {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          max-height: 260px;
          overflow-y: scroll;
          overflow-x: hidden;

          .link_cont {
            background-color: white;
            border-radius: 10px;
            height: 40px;
            display: flex;
            padding-left: 10px;
            padding-right: 10px;
            align-items: center;
            justify-content: center;

            .link_input {
              width: 100%;
              height: 40px;
              background-color: transparent;
              border: none;
              font-family: "PT Sans", sans-serif;
              font-size: 21px;
              color: #201E1F;
              outline: none;
              padding-left: 10px;

              &::placeholder {
                font-family: "PT Sans", sans-serif;
                font-size: 17px;
                color: #201E1F;
                font-weight: 100;
              }
            }

            .icon {
              font-size: 22px;
              color: #4d4d4d;
              cursor: pointer;
            }
          }
        }

        .add_more {
          display: flex;
          gap: 2rem;
          align-items: center;
          padding-left: 15px;
          padding-top: 20px;
          cursor: pointer;

          .plus {
            background-color: #201E1F;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              font-size: 20px;
              color: white;
              font-weight: 900;
            }
          }

          .add_text {
            font-family: "PT Sans", sans-serif;
            font-size: 18px;
            color: #201E1F;
          }
        }
      }

      .bottom_sec {
        padding-top: 50px;

        @media (max-width: 990px) {
          display: none;
        }

        .link_title {
          font-family: "PT Sans", sans-serif;
          font-size: 19px;
          color: #201E1F;
          padding-bottom: 20px;
        }

        .btn_sec {
          display: flex;
          gap: 2rem;

          .btn_upload {
            display: flex;
            justify-content: start;
            align-items: center;
            padding-left: 20px;
            gap: 10px;
            width: 200px;
            height: 40px;
            font-family: "PT Sans", sans-serif;
            font-size: 16px;
            border: none;
            background-color: white;
            color: #201E1F;
            border-radius: 10px;
            font-weight: 600;
            cursor: pointer;

            .icon {
              color: #4d4d4d;
            }
          }
        }
      }
    }
  }

  .material_bottom {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px 60px 20px 0px;
    gap: 2rem;
    position: relative;

    @media (max-width: 990px) {
      background-color: #201E1F;
      padding: 15px 20px 15px 0px;
      justify-content: space-between;
    }

    .back {
      font-family: "PT Sans", sans-serif;
      font-size: 18px;
      font-weight: 200;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      color: #201E1F;
      position: absolute;
      left: 160px;
      top: 25px;

      @media (max-width: 1150px) {
        left: 80px;
      }

      @media (max-width: 990px) {
        display: none;
      }
    }

    .total {
      font-family: "PT Sans", sans-serif;
      font-size: 18px;
      font-weight: 200;
      color: #201E1F;

      @media (max-width: 990px) {
        color: white;
        font-size: 14px;
        position: absolute;
        left: 20px;
      }
    }

    button {
      @media (max-width: 990px) {
        background-color: white;
        color: #201E1F;
        font-size: 17px;
        width: 200px !important;
      }
    }
  }
}
.modal {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 800px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .modal {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .modal {
    padding: 10px;
    top: 60%;
    transform: translate(-50%, -50%);
  }
}

.modal_content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.close_icon {
  font-size: 25px;
  color: #4d4d4d;
  cursor: pointer;
  position: absolute;
  right: 20px;
}

@media (max-width: 500px) {
  .close_icon {
    right: 10px;
  }
}

.modal_body {
  margin-bottom: 20px;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-weight: 200;
  color: #201E1F;
  height: 450px;
  max-height: 300px;
  overflow-y: auto;
}

.main_noteadd {
  /* Existing styles */

  @media (max-width: 990px) {
    .note_content {
      padding: 150px 0px 50px 0px;
    }

    .left_note .top_sec .top_sec_content {
      flex-wrap: nowrap;
      flex-direction: column;
    }

    .left_note .top_sec .note_card,
    .left_note .bottom_sec {
      width: 100%;
    }

    .link_main {
      max-height: 260px;
    }

    .material_bottom {
      padding: 15px 20px 15px 0px;
    }
  }

  /* Additional media query for smaller screens */
  @media (max-width: 500px) {
    .main_noteadd {
      .material_bottom .back {
        left: 10px;
      }

      .modal .modal_content .modal_header .close_icon {
        right: 10px;
      }
    }
  }
}
