.main_carousel {
    font-family: "product-sans-regular-400", sans-serif;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    background-color: #F0F5FF;
    color: #201E1F;
    .mixer_btn {
        display: flex;
        flex-direction: column;
        padding: 13px 20px;
        background-color: white !important;
        border-top-left-radius: 40px;
        cursor: pointer;
        border-bottom-left-radius: 40px;
        position: absolute;
        right: 0;
        top: 130px;
        font-size: 20px;

        // @media(max-width:670px) {
        //     top: 20px;
        //     z-index: 11111111111111111;
        // }

    }

    .carousel_content {
        // padding-top: 140px;
        cursor: pointer;
        @media(max-width:900px) {
            padding-top: unset;
            display: none;
        }
    }

    .slick-next {
        right: -70px !important;
        border: 1px solid #201E1F;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        padding: 4px;

        polyline {
            stroke: #201E1F;
        }

        @media(max-width:900px) {
            display: none !important;
        }

    }

    .slick-track {
        display: flex;
        gap: 1rem;
    }

    .slick-prev {
        left: -60px !important;
        z-index: 111111;
        border: 1px solid #201E1F;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        padding: 4px;

        polyline {
            stroke: #201E1F;
        }

        @media(max-width:900px) {
            display: none !important;
        }
    }

    .slick-slider {
        width: 700px;

        @media(max-width:900px) {
            width: 700px !important;
        }
        @media(max-width:750px) {
            width: 500px !important;
        }
        @media(max-width:500px) {
            width: 350px !important;
        }
    }

    .card {
        background-color: white;
        border-radius: 30px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 50px 40px;

        @media(max-width:900px) {}

        .title {
            color: #201E1F;
            font-size: 30px;
            max-width: 530px;
            text-align: center;
            

            @media(max-width:900px) {
                font-size: 21px;
                line-height: unset !important;
            }
        }

        .para {
            color: #201E1F;
            font-size: 24px;
            text-align: center;
            

            @media(max-width:900px) {
                font-size: 18px;
                color: black;
            }
        }

    }

    .carousel_mobile {
        display: none;
        cursor: pointer;
        @media(max-width:900px) {
            display: block;
            // padding-top: 100px;
        }
    }

    .card_mobile {
        border-radius: 30px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 50px 40px;
        @media(max-width:900px) {}
        .title {
            color: #201E1F;
            font-size: 14px;
            background-color: white;
            text-align: center;
            padding: 20px 30px;
            
            border-radius: 15px;
            @media(max-width:900px) {
                font-size: 21px;
                line-height: unset !important;
            }
        }

        .para {
            color: black;
            font-size: 16px;
            text-align: left;
            
            padding-top: 30px;
        }
    }
}