/* Sidebar */
.sidebar-container {
  background-color: #fff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  /* width: 190px; */
  transition: width 0.1s;
  z-index: 999;
  padding: 50px 30px;
}
.sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.logo-section img {
  height: 20px;
  width: auto;
}
.link-section {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.menu-icon-container{
  display: flex;
  height: 20px;
}
.each-menu-section {
  /* min-width: 130px; */
  /* min-height: 73px; */
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}

.each-menu-section-profile{
  margin-bottom: 25px;
}

.each-menu-section-profile , .each-menu-section-logout {
  display: flex;
  gap: 8px;
  /* align-items: center; */
  margin-top: 2px;
  /* margin-bottom: 30px; */
  cursor: pointer;
  font-size: 14px;
}

.profile-btn{
  margin-bottom: 40px;
}
.link-text {
  color: rgb(85, 85, 85);
  font-family: var(--content-font) !important;
  font-size: 16px;
  letter-spacing: 0px;
  cursor: pointer;
  height: 20px;
}

.link-text-profile {
  color: rgb(85, 85, 85);
  font-family: var(--content-font) !important;
  font-size: 14px;
  letter-spacing: 0px;
  cursor: pointer;
}

.logout-btn-sidebar{
  color: rgb(85, 85, 85);
  font-family: var(--content-font) !important;
  font-size: 14px;
  letter-spacing: 0px;
  cursor: pointer;
}



.menu-icons {
  height: 20px;
  stroke: #92928ec9;
}

.menu-icons-profile , .menu-icons-logout {
  height: 18px;
  stroke: #92928ec9;
}

.bottom-section-link > p {
  font-family: var(--content-font) !important;
  font-weight: 400;
  font-size: 18px;
}
.bottom-section-link {
  margin-bottom: 30px;
}
/* .bottom-section-link p {
  margin-top: 10px;
} */
.pushmode-button-container {
  margin-top: 20px;
  /* min-height: 140px; */
}
.pushmode-cta {
  background-color: #f18f01;
  padding: 10px 20px;
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  border-radius: 15px;
  cursor: pointer;
  display: inline-block;
}
.pushmode-button-container aside h6 {
  color: #f18f01;
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0px;
}
.pushmode-button-container aside p {
  font-family: var(--content-font) !important;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0px;
}
.sidebar-backdrop {
  background-color: #000;
  opacity: 0.5;
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  display: none;
  cursor: pointer;
}
/* Topbar */
.topbar-container {
  padding: 20px 20px;
}
.topbar-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.menu-icon {
  height: 16px;
  width: auto;
  cursor: pointer;
}

.sidebar-container-show {
  display: block;
}
.sidebar-container-hide {
  display: none;
}
.dende-logo {
  width: auto;
  height: 17px;
  margin-left: 15px;
  cursor: pointer;
}
@media screen and (max-width: 1450px) {
  .sidebar-container {
    width: 170px;
    padding: 50px 25px;
  }
  .pushmode-cta {
    padding: 10px 13px;
  }
}
@media screen and (max-width: 1000px) {
  .menu-icon-fixed{
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 10;
  }
  .sidebar-container {
    display: none;
  }
}
@media screen and (max-height: 700px) {
  .link-text {
    margin-bottom: 2px;
    font-size: 18px;
  }
  .link-section {
    margin-top: 30px;
  }
  /* .bottom-section{
        margin-bottom: 40px;
    } */
}

@media only screen and (max-width: 1000px) and (min-width: 300px){
  .each-menu-section {
    margin-bottom: 20px;
  }

  .bottom-section-link .each-menu-section {
    margin-bottom: 10px;
  }

  .pushmode-button-container{
    min-height: auto;
  }
}

@media only screen and (max-height: 600px) {
  .each-menu-section {
    margin-bottom: 5px;
  }

  /* .bottom-section-link .each-menu-section {
    margin-bottom: 10px;
  } */

}
