.main_material {
    background-color: #f0f6f5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  

    @media(max-width:850px) {
        overflow: scroll;
        align-items: center;
    }

    .material_content {
        display: flex;
        gap: 8rem;
        padding: 150px 0px 50px 130px;
        height: 100vh;
        @media(max-width:900px) {
            gap: 1.5rem;
        }

        @media(max-width:850px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 100px 0px 50px 0px;
        }

        .material_text {


            .material_title {
                
                font-size: 48px;
                color: #201E1F;

                @media(max-width:900px) {
                    font-size: 26px;
                    line-height: unset !important;
                }

              
            }

            .material_para {
                
                font-size: 21px;
                color: #201E1F;

                @media(max-width:900px) {
                    font-size: 16px;
                    padding-top: 10px;
                }

                @media(max-width:850px) {
                    font-size: 14px;
                }
            }

        }

        .main_card {

            display: flex;
            gap: 2rem;
            flex-direction: column;

            .cards_spec {
                height: 300px;
            }

            .card {

                background-color: white;
                border-radius: 10px;
                padding: 30px 20px 0px 20px;
                width: 300px;
                height: 140px;
                position: relative;
                @media(max-width:850px) {
                  height: 80px;
                  padding: 0px 20px 0px 20px;
                  display:flex ;
                  justify-content: center;
                  flex-direction: column;
                 }
                .upper_sec {
                    display: flex;
                    gap: 20px;

                    .icon {
                        color: #4D4D4D;
                        font-size: 28px;
                    }

                    .card_text {

                        .card_title {
                            
                            font-size: 26px;
                            color: #201E1F;
                            @media(max-width:850px) {
                                font-size: 18px;
                            }
                        }

                        .card_para {
                            
                            font-size: 18px;
                            color: #201E1F;
                            font-weight: 100;
                            @media(max-width:850px) {
                                font-size: 14px;
                            }
                        }

                    }
                }


                .count {
                    
                    font-size: 18px;
                    color: #201E1F;
                    font-weight: 100;
                    display: flex;
                    justify-content: end;
                    align-items: end;
                    width: 100%;
                    position: absolute;
                    bottom: 10px;
                    right: 25px;
                    @media(max-width:850px) {
                       display: none;
                    }
                }
            }
        }
    }

    .material_bottom {
        background-color: white;
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 20px 60px 20px 0px;
        margin-top: -80px;
        @media(max-width:850px) {
            justify-content: center;
            background-color: unset;
            align-items: center;
            padding: 20px 0px 20px 0px;
        }
    }


}