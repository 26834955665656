.container {
  display: flex;
  justify-content: center;
  color: #000;
  font-family: var(--content-font);
  font-weight: 400;
}
.dende-logo {
  text-align: center;
  padding: 30px 10px;
}
.dende-logo > img{
  height: 22px;
  width: auto;
  cursor: pointer;
}
.wrapper {
  width: 800px;
  margin-top: 20px;
  text-align: center;
  padding-bottom: 50px;
}
.title {
  font-size: 30px;
  font-weight: 600;
  width: 330px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  letter-spacing: 0px;
}
.description-one {
  font-size: 18px;
  font-weight: 400;
  width: 315px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-style: italic;
  margin-top: 20px;
}
.white-bar{
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 5px;
  left: 0px;
  height: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.orange-bar{
  width: 100%;
  background-color: #f18f01;
  position: absolute;
  top: -20px;
  left: 0px;
  height: 25px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-bottom: 40px;
}
.orange-bar aside{
  display: flex;
  align-items: center;
  justify-content: center;
}
.orange-bar aside p{
  font-family: var(--content-font);
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}
.orange-bar aside img{
  height: 25px;
  width: auto;
}
.upload-box {
  box-shadow: var(--global-box-shadow);
  position: relative;
  background-color: #fff;
  height: 300px;
  display: block;
  width: 560px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  margin-top: 35px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  // border-radius: 20px;
  padding: 50px;
}
.upload-box-content {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.upload-box-content img {
  width: 35px;
  height: auto;
}
.upload-box-content h5 {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  margin-top: 15px;
}
.upload-box-content h6 {
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  margin-top: 10px;
}
.upload-box-content p {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  margin-top: 30px;
}

.copyright-text-container {
  width: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.copyright-text-container p {
  font-size: 18px;
  font-weight: 400;
  // margin-left: 10px;
}
.copyright-text-container p > a{
  color: #000;
  text-decoration: underline;
}
.copyright-text-container input {
  width: 40px;
  height: 27px;
}

.upload-btn {
  background-color: #f18f01;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  padding: 10px 40px;
  border-radius: 60px;
  margin-top: 40px;
}

//changes from drag and drop lib
.sc-fqkvVR {
  display: none !important;
}

.show-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  min-height: 100px;
  // display: block;
  width: 560px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius: 20px;
  // padding: 50px;
  padding: 10px 50px;
}


.file-checkicon {
  font-size: 25px;
  color: #f18f01;
  // cursor: pointer;
}

.file-deleteIcon {
  cursor: pointer;
  font-size: 25px;
}

// selected file progress bar
.uploaded-file-container{
  width: 560px;
  margin: 0px auto;
  margin-top: 20px;
}
.uploaded-file-content{
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  // border-bottom-left-radius: 0px;
  // border-bottom-right-radius: 0px;
  padding-bottom: 30px;
  box-shadow: var(--global-box-shadow);
  position: relative;
}
.uploaded-file-status{
  min-width: 80px;
}
.uploaded-file-status p{
  color: #f18f01;
  font-family: var(--content-font);
  font-weight: 400;
  font-size: 14px;
}
.uploaded-file-loading{
  display: block;
}
.uploaded-file-loading img{
  height: 35px;
  width: auto;
}
.uploaded-file-loading p{
  color: #201E1F;
}
.uploaded-file-done{
  display: block;  
}
.uploaded-file-done img{
  height: 28px;
  width: auto;
}
.uploaded-file-delete{
  min-width: 40px;
  cursor: pointer;
}
.uploaded-file-delete img{
  height: 20px;
  width: auto;
}
.uploaded-file-name{
  overflow-wrap: anywhere;
  font-family: var(--content-font);
  font-weight: 400;
  font-size: 18px;
}
.progress-bar-wrapper{
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
}
.progress-bar{
  height: 25px;
  width: 100%;
  position: relative;
}
.progress-white{
  background-color: #fff;
  height: 10px;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  top: 0px;
  left: 0px;
}
.progress-bar-inner{
  transition: width 1s;
  height: 100%;
  background-color: #f18f01;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}





@media screen and (max-width: 600px){
  .wrapper {
    width: 85vw;
  }
  .title{
    width: 100%;
  }
  .description-one{
    width: 100%;
  }
  .upload-box{
    width: 100%;
  }
  .upload-box-content{
    width: 100%;
  }
  .copyright-text-container{
    width: 100%;
  }
  .copyright-text-container p{
    text-align: left;
  }
  .show-file {
    width: 100%;
    gap: 5px;
    padding: 10px 20px;
    // height: 0;
  }
  .uploaded-file-container{
    width: 100%;
  }
  .uploaded-file-name{
    font-size: 16px;
  }
  .uploaded-file-status{
    min-width: 60px;
  }
  .uploaded-file-loading img{
    height: 30px;
  }
  .uploaded-file-done img{
    height: 23px;
  }
  .uploaded-file-status p{
    font-size: 13px;
  }
}

