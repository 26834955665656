.main_topbar {
    display: flex;
    background-color: white;
    padding: 20px 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 10000000;
    position: absolute;
   

.back{
    background-color: gainsboro;
    color: #4d4d4d;
    width: 40px;
    height: 40px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items:center ;
    border-radius: 50%;
    position: absolute;
    left: 50px;
    cursor: pointer;
    @media(max-width:750px) {
       left: 10px;
        }
}

    .question {
        max-width: 60%;
        font-family: "PT Sans", sans-serif;
        font-size: 26px;
        font-weight: 700;
        text-align: center;
        color: #201E1F;

        @media(max-width:1050px) {
            font-size: 21px;
            max-width: 450px;
        }
        @media(max-width:750px) {
           max-width: 55%;
           font-size: 12px;
           text-align: center;
           }
    }


    .actions {
        display: flex;
        position: absolute;
        right: 50px;
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        @media(max-width:750px) {
          right: 10px;

           }
        .text {
            font-family: "PT Sans", sans-serif;
            font-size: 16px;
            color: #201E1F;
            text-decoration: underline;
            cursor: pointer;
            @media(max-width:950px) {
                display: none;
            }
        }

    }

    .icon {
        cursor: pointer;
        font-size: 30px;
        font-weight: 700;
        color: #4d4d4d;
    }
}

// #root {
//     // background-color: #F0F6F5 !important;
//     background-color: var(--pearl-white) !important;
//     min-height: 100vh;

// }