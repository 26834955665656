.stat_main{
// display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 50px;
gap: 1rem;
    .title{
        padding-top: 50px;
        padding-bottom: 20px;
        color: #201E1F;
        // font-size: 26px;
           
    }
    .stat_content{
        .card_main {
            display: flex;
            gap: 1.5rem;
            padding-bottom: 30px;

            @media(max-width:800px) {
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                padding-top: unset;
            }

            .card {
                background-color: #201E1F;
                width: 240px;
                padding: 25px 20px 25px 15px;
                border-radius: 15px;
                height: 150px;
                @media(max-width:1350px) {
                    height: 130px;
                }
                .subhead {
                    font-size: 21px;
                    
                    color: white;

                    @media(max-width:1350px) {
                        font-size: 17px;
                    }
                }

                .number {
                    font-size: 36px;
                    
                    color: white;
                    font-weight: bolder;

                    @media(max-width:1350px) {
                        font-size: 26px;
                    }
                }

            }

        }
    }

}