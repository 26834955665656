.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #201E1F;
    font-size: 21px;

    @media (max-width: 750px) {
      font-size: 14px;
    }
  }
}

.ant-checkbox-checked:after {
  border: unset !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #d9d9d8 !important;
  margin-top: 6px;
  border-radius: 2px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #201E1F !important;
  border-color: #201E1F !important;
}

.ant-checkbox {
  @media (max-width: 750px) {
    margin-bottom: 12px;
  }
}

.popUp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 450px;
  padding: 15px 25px 35px 25px;
  //   padding: 50px 45px;
  background-color: white;
  border-radius: 8px;
}

.modalOverlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.5);
  // background-color: #608681;
  background-color: rgb(240 245 255 / 93%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popHead {
  text-align: center;
  font-size: 25px;
  color: #201E1F;
  font-weight: 700;
  width: 210px;
  margin: auto;
}

.subHead {
  text-align: center;
  color: #201E1F;
  font-size: 20px;
}

.midHead {
  margin: 30px 0px;
  width: 280px;
}

.mailImg {
  width: 130px;
  height: 130px;
  //   border: 1px solid black;
  //   margin-bottom: 30px;
}

.parentImg {
  width: fit-content;
  margin: auto;
}
.signup-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup-wrapper {
  padding-top: 20px;
  width: 340px;
  // height: 100vh;
}

.signup-content {
  // width: 340px;
}

.signup-title {
  font-size: 34px;
  text-align: center;
  color: #201E1F;
  letter-spacing: 1px;
  margin-bottom: 20px;
  font-family: var(--title-font) !important;
  font-weight: 500;
  letter-spacing: 0px;
}

.signup-form input {
  width: 100%;
  margin-bottom: 15px;
}

.signup-password-container {
  position: relative;
}

.signup-password-container .signup-eye {
  position: absolute;
  right: 25px;
  top: 18px;
}

.signup-checkbox label {
  justify-content: flex-start;
  margin-inline-start: 0px !important;
}
.signup-checkbox label span{
  font-size: 18px !important;
  font-weight: 400 !important;
}
.signup-btn-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup-btn {
  background-color: #F18F01;
  color: #fff;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  padding: 10px 30px;
  border-radius: 60px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  font-family: var(--title-font) !important;
  font-weight: 500;
  letter-spacing: 0px;
}

.signup-google-sso-container{
  // margin-top: 30px;
  text-align: center;
}
.signup-google-sso-divider {
  border-bottom:1px solid #000;
  position: relative;
  margin-top: 30px;
  margin-bottom: 35px;
}
.signup-google-sso-divider span{
  font-size: 20px;
  color: #000;
  position: absolute;
  left: 50%;
  top: -14px;
  background-color: #f0f6f5;
  margin-left: -6px;
}
.signup-google-sso-text{
  font-size: 34px;
  font-family: var(--title-font) !important;
  font-weight: 500;
  letter-spacing: 0px;
  color: #201E1F;
  margin-bottom: 10px;
}
.signup-google-sso-button{
  text-align: center;
  margin-top: 10px;
  display: inline-block;
}
.signup-google-sso-button button{
  background-color: #fff;
  padding: 5px 25px;
  font-size: 22px;
  border-radius: 30px;
  cursor: pointer;
  color: #000;
  font-weight: 600;
}
.signup-google-sso-button button img{
  margin-right: 12px;
  height: 40px;
  width: auto;
}

.signup-custom-checkbox{
  font-size: 16px;
}
.signup-custom-checkbox a{
  color: #000;
  text-decoration: underline;
}
.signup-custom-checkbox{
  position: relative;
  cursor: pointer;
  margin-right: 50px;
  display: block;
  padding-left: 40px;
}
.signup-custom-checkbox > input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.signup-checkmark{
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid orange;
  border-radius: 5px;
}
.signup-custom-checkbox input:checked ~ .signup-checkmark {
  background-color: orange;
}
/* Create the checkmark/indicator (hidden when not checked) */
.signup-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.signup-custom-checkbox input:checked ~ .signup-checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.signup-custom-checkbox .signup-checkmark:after {
  left: 7px;
  top: 2px;
  width: 8px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.signup-textinput-container{
  position: relative;
}
.signup-textinput-container > span{
  position: absolute;
  top: 3px;
  left: 15px;
  font-size: 11px;
}
.signup-gcaptcha-container{
  margin-top: 25px;
  margin-bottom: 0px;
  text-align: center;
}
.signup-gcaptcha{
  display: inline-block;
}

.custom-input{
  background-color: #fff !important;
  box-shadow: var(--global-box-shadow);
  font-size: 15px;
  font-family: var(--content-font) !important ;
  font-weight: 400;
  padding: 15px 20px;
  border: none;
  outline: none;
  border-radius: 30px;
  width: 340px;
}

@media screen and (max-width: 400px) {
  .signup-wrapper {
    width: 85vw;
  }
  .signup-title{
    font-size: 30px;
  }
  .signup-google-sso-text{
    font-size: 30px;
  }
}