.main_send {
    background-color: #f0f6f5;
    padding-bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 100px;

    .message_content {
        width: 650px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        padding-bottom: 50px;
        padding-top: 50px;
        position: relative;

        @media(max-width:700px) {
            width: 90%;
        }

        .close {
            font-size: 25px;
            color: #4D4D4D;
            position: absolute;
            top: 25px;
            cursor: pointer;
            right: 25px;
        }

        .message_material {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;

            .icon {
                font-size: 80px;
                color: #201E1F;
            }

            .title {
                
                font-size: 26px;
                color: #201E1F;
                padding-bottom: 20px;
                max-width: 300px;
                opacity: 0.9;
                text-align: center;

                @media(max-width:700px) {
                    font-size: 18px;
                }
            }

            .feed_form {
                display: flex;
                flex-direction: column;
                gap: 15px;
            }

            textarea {
                border: none;
                padding-left: 10px;
                background-color: #F0F6F5;
                border-radius: 10px;
                outline: none;
                padding-top: 10px;
                resize: none;

                &::placeholder {
                    color: #201E1F;
                    
                    font-size: 13px;
                }
            }

            .para {
                max-width: 195px;
                opacity: 0.9;
                font-weight: 100;
                padding-top: 20px;
                
                font-size: 18px;
                color: #201E1F;
                text-align: center;
            }
        }

    }

}