.container{
    display: flex;
    justify-content: center;
    font-family: var(--content-font) !important;
    font-weight: 400;
    color: #201E1F;
}
.content{
    margin-top: 30px;
    width: 350px;
    text-align: center;
}
.title{
    font-size: 35px;
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
    letter-spacing: 0px;
}
.input-box-container{
    margin-top: 70px;
}
.input-fields{
    margin-top: 25px;
    position: relative;
}
.input-fields > input{
    display: block;
    width: 100%;
    padding: 10px 20px;
    border: 0px;
    border-radius: 50px;
    font-size: 17px;
    background-color: #c3c6cc;
    pointer-events: none;
}
.input-fields > input:focus{
    outline: none;
}
.input-fields > p{
    margin-top: 15px;
    font-size: 16px;
    cursor: pointer;
}
.input-fields > aside{
    position: absolute;
    top: 12px;
    right: 20px;
}
.input-fields > aside > svg{
    height: auto;
    width: 20px;
}
.save-btn{
    background-color: #F18F01;
    font-size: 18px;
    padding: 8px 25px;
    border-radius: 50px;
    margin-top: 20px;
    color: #fff;
    font-weight: 600;
    font-family: var(--sub-title-font) !important;
    font-weight: 600;
    letter-spacing: 0.5px;
}