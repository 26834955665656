.feedback-popup-container{
    color: #201E1F;
    position: absolute;
    top: 0px;
    display: none;
}
.feedback-popup-backdrop{
    background-color: #27345eba;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.feedback-popup-content{
    z-index: 2;
    background-color: #fff;
    width: 450px;
    border-radius: 25px;
    padding: 45px 40px;
    position: relative;
}
.feedback-popup-close-icon{
    position: absolute;
    height: 30px;
    width: auto;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.feedback-popup-content h4{
    text-align: center;
    margin-bottom: 30px;
    font-family: var(--sub-title-font) !important;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0px;
}
.checkbox-options{
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
}
.checkbox-options label{
    font-family: var(--content-font) !important;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0px;
    width: 100%;
}
.checkbox-options input{
    height: 30px;
    width: 20px;
    margin-right: 10px;
}
.feedback-popup-btn-container{
    text-align: center;
    margin-top: 40px;
}
.feedback-popup-btn-container button{
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
    letter-spacing: 0px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 40px;
    border-radius: 60px;
    letter-spacing: 1px;
}
.feedback-popup-btn-active{
    background-color: #f18f01;
    pointer-events: auto;
}
.feedback-popup-btn-inactive{
    background-color: #f5b04d;
    pointer-events: none;
}

.link_input {
    padding-left: 5px;
    width: 100%;
    height: 80px;
    resize: none;
    overflow-y: hidden;
    margin: 10px;
    font-family: var(--content-font) !important;
    font-weight: 400;
    font-size: 15px;
    color: #201E1F;
    outline: none;

    @media (max-width: 990px) {
      width: 100%;
      font-size: 14px;
    }
    &::placeholder {
      font-family: var(--content-font) !important;
      font-weight: 400;
      font-size: 15px;
      color: #201E1F;
      font-weight: 100;
      padding-bottom: 15px;
      @media (max-width: 990px) {
        font-size: 14px;
      }
    }
  }


  @media screen and (max-width: 400px){
    .feedback-popup-content{
        width: 95%;
    }
  }