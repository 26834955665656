.main_linkadd {
  background-color: #f0f6f5;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  overflow: auto;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }

  .linkadd_content {
    display: flex;
    padding: 130px 0px 70px 110px;
    gap: 7rem;
    @media (max-width: 990px) {
      padding: 130px 0px 0px 0px;
      gap: unset;
      justify-content: center;
    }
    .left_link {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 500px;
      @media (max-width: 990px) {
        width: 85%;
      }
      @media (max-width: 750px) {
        width: 95%;
      }
      .top_sec {
        @media (max-width: 990px) {
          width: 100%;
        }
        .link_title {
          font-family: "PT Sans", sans-serif;
          font-size: 26px;
          color: #201E1F;
          padding-bottom: 20px;
        }
        .link_text {
          font-family: "PT Sans", sans-serif;
          font-weight: 400;
          font-style: normal;
          color: rgb(0, 60, 52);
          text-decoration: none;
        }
        .note {
          font-family: "PT Sans", sans-serif;
          font-size: 14px;
          color: #201E1F;
          padding-bottom: 20px;
        }

        #style-2::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: white;
        }

        #style-2::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }

        #style-2::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #201E1F;
        }
      }

      .link_main {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        gap: 1.3rem;
        margin-top: 20px;
        @media (max-width: 990px) {
          width: 100%;
        }
        .card_sec {
          margin-top: 20px;
          @media (max-width: 990px) {
            width: 100%;
          }
          @media (max-width: 550px) {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .link_card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            width: 350px;
            cursor: pointer;
            border-radius: 15px;
            flex-direction: "column";
            height: "100%";

            @media (max-width: 990px) {
              width: 90%;
            }

            .link_card_main {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
              margin-bottom: 20px;
              background-color: #f5f5f5;
              border-radius: 15px;
              flex-direction: "column";
              height: "100%";

              .link_input {
                width: 100%;
                height: 80%;
                overflow-y: hidden;
                margin: 10px;
                font-family: "PT Sans", sans-serif;
                font-size: 15px;
                color: #201E1F;
                outline: none;

                @media (max-width: 990px) {
                  width: 100%;
                  font-size: 14px;
                }
                &::placeholder {
                  font-family: "PT Sans", sans-serif;
                  font-size: 15px;
                  color: #201E1F;
                  font-weight: 100;
                  padding-bottom: 15px;
                  @media (max-width: 990px) {
                    font-size: 14px;
                  }
                }
              }
              .icon {
                font-size: 22px;
                color: #4d4d4d;
                cursor: pointer;
                margin-left: 10px;
              }
            }

            .link_input {
              width: 100%;
              height: 50px;
              background-color: #f5f5f5;
              border: none;
              border-radius: 15px;
              font-family: "PT Sans", sans-serif;
              font-size: 15px;
              color: #201E1F;
              outline: none;
              padding: 20px;
              margin-top: 30px;
              @media (max-width: 990px) {
                width: 100%;
                font-size: 14px;
              }
              &::placeholder {
                font-family: "PT Sans", sans-serif;
                font-size: 15px;
                color: #201E1F;
                font-weight: 100;
                padding-bottom: 15px;
                @media (max-width: 990px) {
                  font-size: 14px;
                }
              }
            }

            .link_card_footer {
              display: flex;
              align-items: center;
              justify-content: start;
              margin-bottom: 33px;

              .save_btn {
                background-color: #201E1F;
                color: white;
                padding: 8px 25px 10px 25px;
                border: none;
                cursor: pointer;
                border-radius: 30px;
                font-weight: 700;
                display: flex;
                width: 100px;
                justify-content: start;
                align-items: start;
              }

              .del_btn {
                color: #201E1F;
                margin-left: 10px;
              }
            }
          }

          .link_card_add {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: white;
            height: 293px;
            width: 350px;
            cursor: pointer;
            border-radius: 15px;

            @media (max-width: 990px) {
              width: 90%;
            }

            .card_title {
              font-family: "PT Sans", sans-serif;
              font-size: 21px;
              color: #201E1F;
              font-weight: 300;
              padding-top: 20px;
            }

            .plus {
              background-color: #201E1F;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              .icon {
                font-size: 20px;
                color: white;
                font-weight: 900;
                @media (max-width: 990px) {
                  font-size: 14px;
                }
              }
            }

            .add_text {
              font-family: "PT Sans", sans-serif;
              font-size: 18px;
              color: #201E1F;
              margin-top: 20px;

              @media (max-width: 990px) {
                font-size: 14px;
              }
            }
          }
        }
      }

      .bottom_sec {
        margin-top: 10px;
        padding-bottom: 103px;

        @media (max-width: 990px) {
          display: none;
        }
        .link_title {
          font-family: "PT Sans", sans-serif;
          font-size: 19px;
          color: #201E1F;
          padding-bottom: 20px;
        }

        .btn_sec {
          display: flex;
          gap: 2rem;

          .btn_upload {
            display: flex;
            justify-content: start;
            align-items: center;
            padding-left: 20px;
            gap: 10px;
            width: 200px;
            height: 40px;
            font-family: "PT Sans", sans-serif;
            font-size: 16px;
            border: none;
            background-color: white;
            color: #201E1F;
            border-radius: 10px;
            font-weight: 600;
            cursor: pointer;

            .icon {
              color: #4d4d4d;
            }
          }
        }
      }
    }
  }

  .material_bottom {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px 60px 20px 0px;
    gap: 2rem;
    bottom: 0;
    position: fixed;

    @media (max-width: 990px) {
      background-color: #201E1F;
      padding: 15px 20px 15px 0px;
      justify-content: space-between;
    }

    .back {
      font-family: "PT Sans", sans-serif;
      font-size: 18px;
      font-weight: 200;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      color: #201E1F;
      position: absolute;
      left: 160px;
      top: 25px;

      @media (max-width: 1150px) {
        left: 80px;
      }

      @media (max-width: 990px) {
        display: none;
      }
    }

    .total {
      font-family: "PT Sans", sans-serif;
      font-size: 18px;
      font-weight: 200;
      color: #201E1F;

      @media (max-width: 990px) {
        color: white;
        font-size: 14px;
        position: absolute;
        left: 20px;
      }
    }

    button {
      @media (max-width: 990px) {
        background-color: white;
        color: #201E1F;
        font-size: 17px;
      }
    }
  }
}
