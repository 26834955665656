


.main_login {
    font-family: var(--content-font) !important ;
    font-weight: 400;
    height: 80vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #201E1F;
    gap: 30px;
    @media(max-width:600px) {
      gap: 20px;
    }
    .login_title {
        color: #201E1F;
        font-size: 48px;
        padding-top: 20px;
        font-family: var(--title-font) !important;
        font-weight: 500;
    }
    
    .detail{
        display: none;
        @media(max-width:600px) {
            display: flex;
            color: #4D4D4D;
            font-size: 16px;
            padding-right: 180px;
        }
    }

    .login_input {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        .edit{
            color: #201E1F;
            font-size: 15px;
            padding-left: 10px;
            cursor: pointer;
            
          

        }

        .main_pass {
            width: 300px;
            height: 50px;
            margin-top: 10px;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            position: relative;

            .eye {
                cursor: pointer;
                position: absolute;
                right: -3px;
                top: 15px;
                font-size: 20px;
            }
        }

    }

}

.login_title{
    font-size: 35px !important;
} 
.forget-password{
    margin-left: auto;
    font-size: 15px;
    cursor: pointer;
}
.login-btn{
    background-color: #F18F01;
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 60px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    font-family: var(--sub-title-font);
    font-weight: 600;
}

.login-google-sso-container{
    margin-top: 0px;
    width: 315px;
    text-align: center;
}
.login-google-sso-divider {
    border-bottom:1px solid #000;
    position: relative;
}
.login-google-sso-divider span{
    font-size: 20px;
    color: #000;
    position: absolute;
    left: 50%;
    top: -14px;
    background-color: #f0f6f5;
    margin-left: -10px;
}
.login-google-sso-text{
    font-size: 15px;
    margin-top: 20px;
    text-align: center;
}
.login-google-sso-button{
    text-align: center;
    margin-top: 10px;
    display: inline-block;
}
.login-google-sso-button button{
    background-color: #fff;
    padding: 5px 25px;
    font-size: 22px;
    border-radius: 30px;
    cursor: pointer;
    color: #000;
    font-weight: 600;

}
.login-google-sso-button button img{
    margin-right: 12px;
    height: 40px;
    width: auto;
}
.reset-password-container{
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
    background-color: #27345e8a;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
}
.reset-password-content{
    border-radius: 20px;
    background-color: #F0F5FF;
    width: 500px;
    min-height: 300px;
    text-align: center;
    padding: 20px 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.reset-password-content h2{
    font-size: 35px;
    font-family: var(--title-font) !important;
    font-weight: 500;
}
.reset-password-content input{
    margin-top: 30px;
    display: block;
    width: 100%;
    padding: 10px;
    border: 0px;
    border-radius: 30px;
    font-size: 17px;
}
.reset-password-content input:focus{
    border: 0px;
    outline: none;
}
.reset-password-content button{
    background-color: #F18F01;
    font-size: 20px;
    padding: 10px 32px;
    border-radius: 30px;
    margin-top: 35px;
    color: #fff;
    font-family: var(--title-font) !important;
    font-weight: 500;
}
.reset-password-content p{
    font-size: 16px;
    margin-top: 10px;
}
.reset-password-content p span{
    color: #F18F01;
}
.reset-password-content h3{
    font-size: 20px;
    margin-top: 25px;
    font-weight: 400;
    line-height: 1.1;
}
.login-textinput-container{
    position: relative;
}
.login-textinput-container > span{
    position: absolute;
    top: 3px;
    left: 15px;
    font-size: 11px;
}
.google-sso-popup{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.google-sso-popup-h1{
    font-family: var(--title-font) ;
    font-weight: 500px;
    font-size: 25px !important;
    line-height: 1.1;
}
.google-sso-popup-h1-margin{
    // margin-top: 40px;
}

.custom-input{
    background-color: #fff !important;
    box-shadow: var(--global-box-shadow);
    font-size: 15px;
    font-family: var(--content-font) !important ;
    font-weight: 400;
    padding: 15px 20px;
    border: none;
    outline: none;
    border-radius: 30px;
    width: 340px;
}

@media screen and (max-width: 550px) {
    .reset-password-content{
        width: 95%;
        padding: 20px 25px;
    }
}
@media screen and (max-width: 400px) {
    .google-sso-popup-h1-margin{
        // margin-top: 25px;
    }
}