.container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 80vh;
    font-family: "product-sans-regular-400", sans-serif;
    color: #201E1F;
}
.content{
    width: 900px;
}
.slider-container{
    // position: relative;
    height: 400px;
    display: flex;
    align-items: center;
}
.slider{
    box-shadow: var(--global-box-shadow);
    position: relative;
    background-color: #fff;
    min-height: 200px;
    padding: 30px;
    border-radius: 30px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.slider-arrow{
    background-color: #fff;
    border-radius: 50%;
    padding: 5px;
    top: 170px;
}
.slider-arrow-click{
    cursor: pointer;
}
.slider-arrow-left{
    position: absolute;
    left: -45px;
    top: 40%;
    // margin-bottom: 0px;
}
.slider-arrow-left > img{
    width: auto;
    height: 30px;
    transform: rotate(180deg);
}
.slider-arrow-right{
    position: absolute;
    right: -45px;
    top: 40%;
    // margin-bottom: auto;
}
.slider-arrow-right > img{
    width: auto;
    height: 30px;
}
.question{
    font-size: 25px;
    text-align: center;
    font-family: var(--sub-title-font) !important;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 45px;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
.answer{
    font-family: var(--content-font) !important;
    font-weight: 400;
    font-size: 22px;
    line-height: 35px;
    text-align: center;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
.filter-backdrop{
    position: fixed;
    z-index: 999;
    background-color: #f7fafcc9;
    width: 100vw;
    height: 100vh;
}
.filter-content{
    box-shadow: var(--global-box-shadow);
    position: fixed;
    z-index: 9999;
    background-color: #fff;
    top: 100px;
    right: 0px;
    width: 285px;
    height: 500px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding: 25px;
}
.filter-close-icon{
    width: auto;
    height: 15px;
    position: fixed;
    top: 120px;
    right: 20px;
    cursor: pointer;
}
.filter-icon{
    box-shadow: var(--global-box-shadow);
    position: fixed;
    top: 100px;
    right: 0px;
    background-color: #fff;
    padding: 12px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    cursor: pointer;
}
.filter-icon img{
    width: auto;
    height: 18px;
}
.filter-title{
    font-size: 28px;
    font-family: var(--sub-title-font) !important;
    font-weight: 600;
    letter-spacing: 0px;

}
.filter-btn-title{
    font-size: 20px;
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
    letter-spacing: 0px;
}
.filter-btn-group{
    display: flex;
    flex-direction: column;
    height: 360px;
    overflow-y: auto;
    padding: 5px;
}
.filter-btn-group .filter-files-btn{
    background-color: var(--pearl-white);
    padding: 5px 8px;
    border-radius: 30px;
    cursor: pointer;
    margin-bottom: 10px;
}

.resource-loading-container{
    position: fixed;
    background-color: var(--pearl-white);
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}
.resource-loading-content{
    text-align: center;
}
.resource-loading-content h2{
    font-family: var(--content-font) !important;
    font-size: 22px;
    font-weight: 400;
}
.resource-loading-content img{
    height: 110px;
    width: auto;
}


@media only screen and (max-width: 1450px) and (min-width: 1000px)  {
    .container{
        margin-left: 220px;
        justify-content: flex-start;
        width: 70vw;
    }
    .content {
        width: 100%;
    }
}
@media only screen and (max-width: 1000px) and (min-width: 500px)  {
    .container{
        margin-left: 0px;
        justify-content: center;
        width: 100vw;
    }
    .content {
        width: 100%;
    }
    .slider{
        width: 75%;
    }
}

@media screen and (max-width: 500px) {
    .content{
        width: 85vw;
    }
    .slider{
        width: 80%;
        padding: 15px;
    }
    .question{
        font-size: 25px;
    }
    .answer{
        font-size: 24px;
    }
    .filter-icon{
        top: 100px;
    }
    .slider-arrow-left{
        left: -50px;
    }
    .slider-arrow-right{
        right: -50px;
    }
}

