.container {
  display: grid;
  grid-template-rows: calc(100vh);
  // grid-template-columns:  750px calc(100vw - 880px);
  grid-template-columns: minmax(0, 50vw) minmax(0, 50vw);
  // margin-left: 140px;
  margin: 0px 0px 0px 140px;
  gap: 50px;
}
.question-section {
  padding-top: 20px;
}
.pdf-section {
  background-color: #fff;
  padding: 25px 50px;
}
.title-section img {
  height: 25px;
  width: auto;
}
.title-content {
  font-size: 24px;
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  letter-spacing: 0px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.title-content span {
  background-color: #ffb84c;
  padding: 2px 15px;
  border-radius: 30px;
  color: #fff;
  font-size: 24px;
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  letter-spacing: 0px;
}
.question-card {
  border-radius: 5px;
  background-color: #fff;
  // padding: 20px;
  padding-top: 20px;
  // padding-bottom: 10px;
  margin-bottom: 18px;
  // box-shadow: 0 6px 20px rgba(0,0,0,0.2);
  // box-shadow: 10px 10px 5px 12px lightblue;
  // border: 1px solid red;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
}

.question-card-empty {
  border-radius: 5px;
  background-color: #fff;
  padding: 20px 10px 10px 10px;
  margin-bottom: 18px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
}

.question-card-empty p {
  font-size: 22px;
}

.question-text {
  font-size: 20px;
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  letter-spacing: 0px;
  color: #27345e;
  padding: 0px 20px;
}
.answer-text {
  // margin: 0px 20px;
  padding: 15px 20px 20px 20px;
  border-bottom: 1px solid #d8d8d8;
}
.answer-text h6 {
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  font-size: 18px;
  color: #ffb84c;
  margin-bottom: 5px;
}
.answer-text p {
  font-family: var(--content-font) !important;
  font-size: 16px;
  font-weight: 400;
  color: #27345e;
}
.question-card-btns {
  display: flex;
  // height: 40px;
  // margin-top: 10px;
}
.question-btn-box {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
  cursor: pointer;
}

.question-btn-box:hover {
  background-color: #f7f7f7;
}

.question-btn-box button {
  background-color: transparent;
  font-size: 20px;
  font-family: var(--title-font) !important;
  font-weight: 600;
  letter-spacing: 0px;
  color: #27345e;
}
.question-list {
  height: calc(100vh - 150px);
  overflow-y: auto;
  padding: 0px 7px;
}

.pagination-container {
  position: relative;
}
.pagination-wrapper {
  position: absolute;
  top: -30px;
  width: 100%;
}
.pagination-content {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding: 10px 8px;
  font-family: var(--content-font) !important;
  font-size: 16px;
  font-weight: 400;
  box-shadow: -2px 2px 4px 0px #d0d0d0;
}
.pagination-back,
.pagination-next {
  cursor: pointer;
}

// pdf tooltip  color

.rpv-core__tooltip-body {
  color: #ffffff !important;
}

.rpv-core__icon {
  color: #a5adba !important;
}

.rpv-core__tooltip-body-content {
  color: white !important;
}

.pdf_tools_container {
  width: 100%;
  margin: auto;
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-direction: row;
  padding: 3px 40px;
  background-color: white;
}

.back-to-question-section {
  background-color: white;
  align-items: center;
  padding-bottom: 20px;
  display: none;
}

.icon-left {
  height: 20px;
  width: 20px;
}

.back-to-question {
  font-size: 20px;
}

.zoom_automatic_container {
  background-color: #d7d7db;
  width: fit-content;
  padding: 5px 8px;
  display: flex;
  gap: 2px;
  align-items: "center";
  cursor: pointer;
}

.select_container {
  position: absolute;
  top: 0;
  width: 120px;
  border-radius: 5px;
  border: 1px solid #d7d7db;
  background-color: white;
  z-index: 100;
  margin-top: 35px;
  // background-color: ;
}

.select_level_text {
  margin: 0px !important;
  text-align: center;
  padding: 2px 1px;
}

.select_level_text:hover {
  background-color: #d7d7db;
  cursor: pointer;
}

.loading-gif-content {
  height: 100%;
  // width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.loading-gif-content img {
  height: 100px;
  width: auto;
}

.pdf-container-quiz {
  background-color: rgb(214, 212, 212);
  // visibility: hidden;
  display: none;
  padding: 0px;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 40vw;
  height: 100vh;
  transition: transform 1s ease; /* our nice transition */
  transform: translateX(100%);
}

.loading-gif-content-container {
  width: calc(100vw - 200px);
}

.reread-empty-state{
  text-align: center;
}

.reread-empty-state img{
  padding: 10px 0px;
  margin: 10px 0px;

}

.reread_empty_heading{
  font-weight: 500;
  font-size: 20px;
  padding-bottom: 5px;
}

.reread_empty_para{
  color: #27345E;
  font-size: 20px;
  font-family: var(--content-font);
}

@media screen and (max-width: 400px) {
  .loading-gif-content {
    .progress-bar {
      width: 60px;
      height: 60px;
    }
  }
}

@media only screen and (max-width: 1000px) and (min-width: 300px) {
  .loading-gif-content-container {
    width: 100%;
  }

  .back-to-question-section {
    display: flex;
  }

  .container {
    display: block;
    margin: 5px 30px;
  }

  .pdf-section {
    background-color: transparent;
    display: none;
    padding: 0px;
    position: absolute;
    width: 100%;
    // transition: transform 1s ease; /* our nice transition */
    // transform: translateX(100%);
    // -webkit-transform: translateX(100%);
  }

  .pdf_tools_container {
    padding: 3px 0px;
  }

  .question-section {
    padding-top: 10px;
  }

  .title-content {
    margin-top: 0px;
    font-size: 15px;
  }
  .title-content span {
    font-size: 15px;
  }
  .question-text {
    font-size: 15px;
  }
  .answer-text {
    font-size: 15px;
  }
  .answer-text p {
    font-size: 15px;
  }
  .answer-text h6 {
    font-size: 15px;
  }
  .question-btn-box button {
    font-size: 15px;
  }

  .question-card-empty p {
    font-size: 20px;
  }

  .rpv-core__viewer {
    min-height: 100vh;
  }

  .question-list {
    height: calc(100vh - 220px);
  }
}
