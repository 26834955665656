// @import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
:root {
  --title-font: "productsans-bold-700";
  --sub-title-font: "productsans-medium-500";
  --content-font: "product-sans-regular-400";
  --light-font: "productsans-light-300";

  --pearl-white: #F7FAFC;
  --light-blue: #F7FAFC;
  --light-green: #C4D7B2;
  --light-orange: #FFB84C;
  --medium-orange: #F18F01;
  --dark-orange: #E98A00;
  --dark-black: #000000;
  --medium-black: #252C32;
  --light-gray: #67718E;
  --medium-gray: #77787D;
  --stripe-dark-gray: #84919A;
  --dark-violet: #455AE2;
  --refresh-violet: #918FF2;

  --global-box-shadow: 0px 1px 3px 1px rgb(223 223 223), 1px 0px 2px rgb(215 215 215);
  --global-border-radius: 5px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--content-font);
  color: #201e1f;
}
body{
  background-color: var(--pearl-white) !important;
}
// Toastify
.Toastify {
  position: relative;
  z-index: 111111111111111111111111111;
}
.Toastify__toast-body > div:last-child {
  color: #201e1f;
  font-weight: 600;
}
.Toastify__toast {
  border-radius: 10px !important;
}
//PDF section custom  styles
.rpv-core__tooltip-body-content {
  color: #f0f5ff;
}
.rpv-core__textbox {
  width: 35px !important;
  text-align: center !important;
}
.global-h1{
  font-family: var(--sub-title-font);
  font-size: 30px;
  color: var(--dark-black);
  font-weight: 500;
}
.global-h2{
  font-family: var(--sub-title-font);
  font-size: 20px;
  color: var(--dark-black);
  font-weight: 500;
}
.global-h3{
  font-family: var(--sub-title-font);
  font-size: 20px;
  color: var(--dark-black);
  font-weight: 500;
}
.global-h4{
  font-family: var(--sub-title-font);
  font-size: 20px;
  color: var(--dark-black);
  font-weight: 500;
}
.global-h5{
  font-family: var(--sub-title-font);
  font-size: 20px;
  color: var(--dark-black);
  font-weight: 500;
}
.global-h6{
  font-family: var(--sub-title-font);
  font-size: 20px;
  color: var(--dark-black);
  font-weight: 500;
}