.main_users {
    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: 1rem;

    .title{
    color: #201E1F;
    font-size: 26px;
    padding-top: 20px;
    
    }

    .user_content {
        width: 80%;


    .ant-pagination-item {
            border: 1px solid #201E1F !important;

            a {

                color: #201E1F !important;
            }

        }
    }
}

.ant-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ant-btn-primary {
        &:hover {
            background-color: #201E1F !important;
        }
    }
}