.userDetail_main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    .title {
        padding-top: 100px;
        color: #201E1F;
        font-size: 26px;
        
    }

    .userdetail_content {
        padding: 30px 40px 50px 30px;
        height: 400px;
        background-color: white;
        display: flex;
        gap: 1rem;

        @media(max-width:630px) {
            flex-direction: column;
            height: unset;
        }

        .line {
            width: 2px;
            height: 100%;
            background-color: gray;

            @media(max-width:630px) {
                display: none;
            }
        }

        .detail_right {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .feed_form {
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            .bottom_chks {
                display: none;

                @media(max-width:630px) {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }
            }
        }


        .detail_left {
            .login_input {
                display: flex;
                gap: 1rem;
                flex-direction: column;
                align-items: center;

                .main_chks {

                    @media(max-width:630px) {
                        display: none;
                    }
                }

                .main_pass {
                    width: 300px;
                    height: 50px;
                    margin-top: 10px;
                    border-radius: 30px;
                    background-color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    position: relative;

                    @media(max-width:850px) {
                        width: 250px;
                    }

                    .eye {
                        cursor: pointer;
                        position: absolute;
                        right: 15px;
                        top: 15px;
                        font-size: 20px;
                    }
                }
            }
        }

    }

}