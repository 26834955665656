.global-tooltip{
    font-family: var(--content-font);
    font-weight: 400;
    font-size: 14px;
    background-color: #252C32;
    color: #fff;
    width: fit-content;
    padding: 5px;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
    display: none;
    text-align: center;
}
.container{
    display: flex;
    justify-content: center;
}
.content-wrapper {
    width: 1000px;
    margin-top: 0px;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.header-left{
    margin: 0px;
    width: 70%;
}
.back-btn{
    font-family: var(--content-font);
    font-size: 16px;
    font-weight: 400;
    color: var(--dark-black);
    margin-bottom: 20px;
    cursor: pointer;
}
.topic-tile{
    font-family: var(--sub-title-font);
    font-size: 22px;
    font-weight: 500;
    color: var(--dark-black);
}
.topic-description-desktop{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
}
.topic-description-desktop p{
    margin: 0px;
    font-family: var(--light-font);
    font-size: 16px;
    font-weight: 400;
    color: var(--dark-black);
}
.topic-description-mobile{
    display: none;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
}
.topic-description-mobile p{
    margin: 0px;
    font-family: var(--light-font);
    font-size: 16px;
    font-weight: 400;
    color: var(--dark-black);
}
.question-list{
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 100px;
    box-shadow: var(--global-box-shadow);
}
.question-list-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    column-gap: 5px;
}
.header-column-1, .header-column-2{
    font-family: var(--content-font);
    font-size: 19px;
    font-weight: 400;
    color: var(--dark-black);
    letter-spacing: 0px;
    line-height: 26px;
}
.header-column-1{
    width: 80%;
}
.header-column-2{
    width: 125px;
    text-align: center;
}
.question-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 20px 30px;
    border-radius: 5px;
    border-top: 1px solid #E5E9EB;
    cursor: pointer;
}
.question-card:hover{
    background-color: var(--light-blue);
}
.question-card-left{
    width: 80%;
}
.question-card-right{
    width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.question-card-right aside{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: relative;
}
.question-card-right aside:hover .global-tooltip{
    display: inline;
    top: -20px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.question-title{
    font-family: var(--content-font);
    font-size: 17px;
    font-weight: 400;
    color: var(--dark-black);
    letter-spacing: 0px;
    line-height: 26px;
    outline: none;
    border-radius: 5px;
}
.practise-btn-mobile-btn{
    display: none;
}
.practise-btn-desktop-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    box-shadow: 0 0px 1px rgba(0, 0, 0, 0.37), 0 1px 5px rgba(0, 0, 0, 0.14);
    padding: 7px 10px;
    border-radius: 5px;
    cursor: pointer;
}
.practise-btn-desktop-btn img{
    height: 13px;
    width: auto;
}
.practise-btn-desktop-btn p{
    font-family: var(--content-font);
    font-size: 16px;
    font-weight: 400;
    color: var(--dark-black);
}
/* prgress bar */
.progress-bar{
    width: 250px;
    height: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.progress-bar-child{
    height: 100%;
    border-radius: 3px;
    box-shadow: var(--global-box-shadow);
    position: relative;
}
.progress-bar-child:hover .global-tooltip{
    display: inline;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%);
}
/* topic header */
.topic-header{
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    align-items: center;
    padding: 15px 30px;
    border-bottom: 1px solid #E5E9EB;
}
.topic-header-description{
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-top: 4px;
}
.topic-header-left{
    width: 50%;
}
.topic-header-right{
    width: 45%;
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
    align-items: center;
}
.topic-header-title{  
    font-family: var(--sub-title-font) !important;
    font-size: 21px;
    font-weight: 500;
    color: #000;
}
.topic-header-description p{
    font-family: var(--light-font) !important;
    font-size: 17px;
    font-weight: 300;
    color: #000;
    margin: 0px;
}
.topic-header-question-count{
    font-family: var(--light-font) !important;
    font-size: 17px;
    font-weight: 400;
    color: #000;
    width: 125px;
    text-align: center;
}
/* back btn */
.back-btn{
    background-color: #fff;
    border-radius: 5px;
    box-shadow: var(--global-box-shadow);
    padding: 10px 15px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: fit-content;
}
.back-btn svg{  
    height: 15px;
    width: auto;
}
.back-btn span{  
    font-family: var(--light-font) !important;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    
}
.back-btn:hover{
    background-color: #E5E9EB;
}
.loading-gif{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}
.loading-gif svg{
    height: 100px;
    width: auto;
}
@media only screen and (max-width: 1450px){
    .content-wrapper{
        width: 80vw;
        margin-left: 190px;
    }
}
@media only screen and (max-width: 1100px){
    .topic-header{
        flex-direction: column;
        row-gap: 10px;
    }
    .topic-header-left{
        width: 100%;
    }
    .topic-header-right{
        width: 100%;
        /* column-gap: 5px; */
    }
    .progress-bar{
        width: 100%;
    }
    .topic-header-right{
        flex-direction: column-reverse;
        row-gap: 10px;
    }
    .topic-header-progress-bar{
        width: 100%;
    }
    .topic-header-question-count{
        width: 100%;
        text-align: start;
    }
}
@media only screen and (max-width: 1000px){
    .content-wrapper{
        width: 95vw;
        margin-left: 0;
    }
    .question-card-right{
        width: 15%;
    }
    .header-column-2{
        width: 15%;
    }
    .content-wrapper{
        width: 95vw;
        margin-left: 0;
    }
    .header-left{
        width: 100%;
    }
    .topic-description-desktop{
        display: none;
    }
    .topic-description-mobile{
        display: flex;
    }
    .back-btn{
        margin-top: 20px;
    }
}
@media only screen and (max-width: 800px){

    /* .practise-btn-desktop-btn{
        display: none;
    }
    .practise-btn-mobile-btn{
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .practise-btn-mobile-btn img{
        height: 20px;
        width: auto;
        cursor: pointer;
    } */
    /* .question-card-right{
        width: 10%;
    }
    .question-card-left{
        width: 90%;
    } */
}
@media only screen and (max-width: 600px){


    /* .topic-header-description{
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 5px;
    } */
}