.limit-exceed-popup-container {
    background-color: #fff;
    width: 65vw;
    position: absolute;
    top: 100px;
    left: calc(82% - 65vw);
    border-radius: 30px;
    font-family: "PT Sans", sans-serif;
    color: #073f38;
}

.limit-exceed-popup-content {
    padding: 50px 0px;
    text-align: center;
    width: 500px;
    margin: 0px auto;
}

.text1,
.text2 {
    font-size: 22px;
}

.text2 {
    padding: 0px 50px;
    margin-top: 25px;
}

.text3 {
    margin-top: 40px;
}

.text3 h6 {
    font-size: 25px;
}

.text3 p {
    font-size: 18px;
}

.text4 {
    margin-top: 25px;
}

.text4 h6 {
    font-size: 18px;
    margin-bottom: 5px;
}

.text4 p {
    font-size: 22px;
    margin-top: 0px;
}
.close-popup-link p{
    font-size: 15px;
    cursor: pointer;
}

.button-container {
    margin-top: 30px;
    margin-bottom: 10px;
}

.button-container button {
    background-color: #e1501c;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    padding: 18px 100px;
    border-radius: 60px;
}

.close-popup-icon {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
    height: 35px;
    width: auto;
}