@import "rsuite/dist/rsuite.css";

.mixer_btn {
    display: flex;
    flex-direction: column;
    padding: 13px 20px;
    background-color: white !important;
    border-top-left-radius: 40px;
    cursor: pointer;
    border-bottom-left-radius: 40px;
    position: absolute;
    right: 0;
    top: 130px;
    font-size: 20px;

    // @media(max-width:850px) {
    //     top: 15px;
    //     z-index: 11111111111111111111111;
    //     background-color: unset !important;
    // }
    // @media(max-width:500px) {
    //     margin-top: 200px;
    // }
    // @media(max-width:370px) {
    //     margin-right: 200px;
    // }
}
.rs-drawer-right {
    width: 300px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    box-shadow: unset;
    @media(max-width:500px) {
        margin-right: 10%;
        ;
    }
    @media(max-width:370px) {
        margin-right: 8%;
    }
    .rs-drawer-wrapper {
        z-index: 11111111111111111111111;
    }
    .rs-drawer-dialog {
        height: 85%;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .rs-drawer-open {
        padding-right: unset !important;
    }
    .rs-drawer-content {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        @media(max-width:500px) {
            border-radius: 20px !important;
        }
    }
    .rs-drawer-body {
        padding: 10px 0px !important;
        .rs-drawer-body-close {
            display: none;
        }
        .filter_content {
            padding: 0px 25px;
            .title {
                color: #201E1F;
                font-family: "PT Sans", sans-serif;
                font-size: 21px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .top_sec {
                .sub_heading {
                    color: #4D4D4D;
                    
                    font-size: 17px;
                    background-color: unset;
                    font-weight: 600;
                    word-spacing: 4px;
                }
                .btn_sec {
                    display: flex;
                    gap: 1rem;
                    margin-top: 10px;
                    padding-bottom: 20px;
                    button {
                        border: 1px solid #201E1F;
                        background-color: #F0F6F5;
                        color: #201E1F;
                        padding: 10px 20px 10px 20px;
                        border-radius: 50px;
                        cursor: pointer;
                    }
                    button.not-selected {
                        border: 1px solid transparent;
                    }
                }
            }
            .centre_sec {
                padding-top: 20px;
                .sub_heading {
                    color: #4D4D4D;
                    
                    font-size: 17px;
                    background-color: unset;
                    font-weight: 600;
                    word-spacing: 4px;
                }
                .btn_sec {
                    display: flex;
                    gap: 1rem;
                    margin-top: 10px;
                    flex-direction: column;
                    align-items: baseline;
                    button {
                        border: 1px solid #201E1F;
                        background-color: #F0F6F5;
                        color: #201E1F;
                        padding: 10px 40px 10px 20px;
                        border-radius: 50px;
                        cursor: pointer;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 200px;
                    }
                    button.not-selected {
                        border: 1px solid transparent;
                    }
                }
            }
            .centre_next_sec {
                padding-top: 20px;
                .sub_heading {
                    color: #4D4D4D;
                    
                    font-size: 17px;
                    background-color: unset;
                    font-weight: 600;
                    word-spacing: 4px;
                }
                .btn_sec {
                    display: flex;
                    gap: 1rem;
                    margin-top: 10px;
                    flex-direction: column;
                    align-items: baseline;
                    .link {
                        border: 1px solid #201E1F;
                        background-color: #F0F6F5;
                        color: #201E1F;
                        cursor: pointer;
                        padding: 10px 20px 10px 8px;
                        border-radius: 13px;
                        align-items: center;
                        display: flex;
                        gap: 10px;
                    }
                }
            }
        }
        .filter_result {
            .title {
                color: #201E1F;
                font-family: "PT Sans", sans-serif;
                font-size: 21px;
                width: 100%;
                padding: 0px 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 20px;
            }
            .top_sec {
                display: flex;
                flex-direction: column;
                gap: .5rem;
                .sub_heading {
                    padding: 8px 25px;
                    color: #4D4D4D;
                    
                    font-size: 18px;
                    font-weight: 200;
                    word-spacing: 4px;
                }
            }
            .come {
                padding: 0px 25px;
                display: flex;
                gap: 1rem;
                margin-top: 20px;
                align-items: center;
                color: #201E1F;
                font-family: "PT Sans", sans-serif;
                font-size: 16px;
                font-weight: 700;
                .icon {
                    background-color: #201E1F;
                    color: white;
                    width: 40px;
                    cursor: pointer;
                    height: 40px;
                    border-radius: 50%;
                    font-size: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .tips_main {
            padding: 15px 25px;
            .cross {
                font-size: 25px;
                display: flex;
                justify-content: end;
                align-items: end;
            }
            .para_main {
                padding-top: 50px;
                .para {
                    color: black;
                    font-family: "PT Sans", sans-serif;
                    font-size: 21px;
                }
            }
            .next_btn {
                display: flex;
                gap: 10px;
                font-size: 18px;
                padding-top: 40px;
                .icon {
                    color: #201E1F;
                    font-size: 25px;
                    border: 1px solid #201E1F;
                    border-radius: 50%;
                    cursor: pointer;
                    polyline {
                        stroke: #201E1F !important;
                    }
                }
            }
        }
    }
}
.rs-drawer-top {
    width: 300px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    box-shadow: unset;
    .rs-drawer-dialog {
        height: 70%;
        overflow: scroll;
        margin: 20px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .rs-drawer-open {
        padding-right: unset !important;
    }
    .rs-drawer-content {
        border-radius: 20px;
    }
    .rs-drawer-body {
        padding: 10px 0px !important;
        .rs-drawer-body-close {
            display: none;
        }
        .tips_main {
            padding: 15px 25px;
            .cross {
                font-size: 25px;
                display: flex;
                justify-content: end;
                align-items: end;
            }
            .para_main {
                padding-top: 50px;
                @media(max-width:700px) {
                    padding-top: 10px;
                }
                .para {
                    color: black;
                    font-family: "PT Sans", sans-serif;
                    font-size: 21px;
                    @media(max-width:700px) {
                        font-size: 18px;
                    }
                }
            }
            .next_btn {
                display: flex;
                gap: 10px;
                font-size: 18px;
                padding-top: 40px;
                .icon {
                    color: #201E1F;
                    font-size: 25px;
                    border: 1px solid #201E1F;
                    border-radius: 50%;
                    cursor: pointer;
                    polyline {
                        stroke: #201E1F !important;
                    }
                }
            }
        }
    }
}












