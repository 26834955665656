.main_qeust {
    height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: #f0f6f5;
    position: relative;

    
   

    .question_content {
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #F0F6F5;
        gap: 30px;
        padding-top: 140px;

        .card {
            padding: 50px 80px;
            background-color: white;
            border-radius: 30px;

            @media(max-width:850px) {
                width: 90%;
                padding: 30px 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .title {
                color: #201E1F;
                font-size: 30px;
                max-width: 530px;
                text-align: center;
                

                @media(max-width:850px) {
                    font-size: 21px;
                    line-height: unset !important;
                }

            }

        }

        .may {
            
            font-size: 18px;
            color: #201E1F;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
        }

    }

}