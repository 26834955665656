.aloha_main {
    background-color: #F0F6F5;
    .aloha_content {
        padding: 150px 0px 60px 100px;
        display: flex;
        gap: 2rem;
        @media(max-width:1350px) {
            gap: 2rem;
        }
        @media(max-width:1200px) {
            flex-direction: column;
        }
        @media(max-width:800px) {
            padding: 100px 0px 40px 60px;
        }
        @media(max-width:750px) {
            padding: 100px 0px 40px 20px;
        }
        .title {
            font-size: 48px;
            color: #201E1F;
            font-weight: 700;
            
            @media(max-width:800px) {
                text-align: center;
            }
            @media(max-width:1350px) {
                font-size: 26px;
            }
            img {
                width: 48px;
                height: 48px;
                object-fit: contain;
                @media(max-width:1351px) {
                    display: none;
                }
            }
        }
        .aloha_right {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .select_aloha {
                display: flex;
                row-gap: 1rem;
                flex-wrap: wrap;
                column-gap: 2rem;
                max-width: 900px;
                @media(max-width:800px) {
                    max-width: unset;
                    justify-content: center;
                    align-items: center;
                }
                .select {
                    color: #201E1F;
                    background-color: white;
                    font-size: 15.8px;
                    padding-left: 20px;
                    height: 55px;
                    
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    cursor: pointer;
                    width: 270px;
                    font-weight: 700;
                    @media(max-width:1350px) {
                        font-size: 15px;
                    }
                }
            }
            .card_main {
                display: flex;
                gap: 1.5rem;
                padding-top: 30px;
                @media(max-width:800px) {
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    padding-top: unset;
                }
                .card {
                    background-color: #201E1F;
                    width: 200px;
                    padding: 25px 20px 25px 15px;
                    border-radius: 15px;
                    height: 150px;
                    @media(max-width:1350px) {
                        height: 130px;
                    }
                    @media(max-width: 480px){
                        width: 270px;
                    }
                    .subhead {
                        font-size: 20px;
                        
                        color: white;
                        @media(max-width:1350px) {
                            font-size: 17px;
                        }
                    }
                    .number {
                        font-size: 36px;
                        
                        color: white;
                        font-weight: bolder;
                        @media(max-width:1350px) {
                            font-size: 26px;
                        }
                    }
                }
            }
        }
    }
}