.topic-limit-exceed-popup-container{
    display: flex;
    justify-content: center;
    color: #201E1F;
}
.tlep-wrapper{
    width: 900px;
    margin-top: 55px;
    text-align: center;
}
.tlep-heading{
    font-size: 26px;
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
    letter-spacing: 0px;
    color: #27345E;
}
.tlep-text1{
    margin-top: 30px;
    font-size: 18px;
    width: 260px;
    margin-left: auto;
    margin-right: auto;
    font-family: var(--content-font) !important;
    font-weight: 400;
    letter-spacing: 0px;
    color: #27345E;
}
.tlep-btn-container{
    margin-top: 30px;
}
.tlep-btn-container button{
    background-color: #F18F01;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    padding: 13px 37px;
    border-radius: 6px;
    letter-spacing: 1px;
}
.tlep-text2{
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 100px;
    font-size: 16px;
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
    letter-spacing: 0px;
    background-color: #F18F01;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 6px;
    color: #fff;
}
.tlep-text3{
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 100px;
    font-size: 16px;
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
    letter-spacing: 0px;
    display: inline-block;
    border-radius: 6px;
    color: #000;
}
.tlep-topic-card-container{
    margin-top: 30px;
}
.tlep-topic-levels-card {
    margin-left: auto;
    margin-right: auto;
    height: 190px;
    width: 200px;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: relative;
}
.tlep-topic-levels-card p{
    font-family: var(--content-font) !important;
    font-weight: 400;
    letter-spacing: 0px;
    font-size: 17px;
    padding: 20px 20px;
    position: absolute;
    text-align: left;
    color: #27345E;
}

@media screen and (max-width: 500px){
    .tlep-wrapper{
        width: 85vw;
        margin-top: 20px;
    }

    .tlep-heading{
        font-size: 25px;
    }
}