.payment-popup-container{
    color: #201E1F;
    position: absolute;
    top: 0px;
    display: none;
    z-index: 10;
    font-family: "product-sans-regular-400", sans-serif;
}
.payment-popup-backdrop{
    background-color: #f7fafcc7;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment-popup-content{
    box-shadow: var(--global-box-shadow);
    background-color: #fff;
    width: 450px;
    border-radius: 25px;
    padding: 45px 40px;
    position: relative;
}
.payment-popup-close-icon{
    position: absolute;
    height: 30px;
    width: auto;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.payment-popup-content h4{
    text-align: center;
    margin: auto;
    font-size: 25px;
    margin-bottom: 30px;
    width: 250px;
}
.payment-popup-content h3{
    text-align: center;
    font-size: 45px;
    margin-bottom: 10px;
}
.checkbox-options{
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
}
.checkbox-options label{
    font-size: 18px;
}
.checkbox-options input{
    height: 30px;
    width: 20px;
    margin-right: 10px;
}
.payment-popup-btn-container{
    text-align: center;
    margin-top: 40px;
}
.payment-popup-btn-container button{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 40px;
    border-radius: 60px;
    letter-spacing: 1px;
}
.payment-popup-btn-active{
    background-color: #F18F01;
    pointer-events: auto;
}
.payment-popup-btn-inactive{
    background-color: #f0a88e;
    pointer-events: none;
}


@media screen and (max-width: 400px){
    .payment-popup-content{
        width: 95%;
    }
    .payment-popup-content h4{
        font-size: 20px;
        width: 100%;
    }
    .payment-popup-btn-container button{
        padding: 10px 15px;
    }
}