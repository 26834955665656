@property --progress-value {
  syntax: "<integer>";
  inherits: false;
  initial-value: 0;
}

$progress-value: 0; // Define the custom property as a variable

@keyframes html-progress {
  to {
    --progress-value: 100;
  }
}

.loading-container {
  z-index: 112;
  position: absolute;
  top: 0px;
}
// .loading-gif-content{
//     height: 100vh;
//     width: 100vw;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-color: #ffffffd0;
// }
// .loading-gif-content img{
//     height: 100px;
//     width: auto;
// }

.loading-gif-content {
  z-index: 50;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--pearl-white);
  gap: 30px;
  @media (max-width: 600px) {
    gap: 20px;
  }

  .childofload {
    height: 100px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .progress-bar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
    /* to center the percentage value */
    display: flex;
    // justify-content: center;
    align-items: center;
  }

  .content-parent {
    text-align: center;
    line-height: 2;
    margin-top: 10px;
    font-size: 18px;
  }

  .html {
    background: radial-gradient(closest-side, #f0f5ff 79%, transparent 80% 100%),
      conic-gradient(#f18f01 calc(var(--progress-value) * 1%), white 0);
    // animation: html-progress 5s 1 forwards;
  }

  h2 {
    text-align: center;
  }

  progress {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

@media screen and (max-width: 500px) {
  .loading-gif-content {
    .progress-bar {
      width: 60px;
      height: 60px;
    }
  }
  .loading-container {
    width: 85vw !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
