.topic-container {
  display: flex;
  justify-content: center;
  font-family: var(--content-font) !important;
  font-weight: 400;
  color: #201E1F;
}

.topic-wrapper {
  width: 1000px;
  margin-top: 20px;
}

.topic-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.topic-title h2 {
  font-size: 30px;
  letter-spacing: 1px;
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  letter-spacing: 0px;
}

.topic-title p {
  font-size: 18px;
}

.topic-title-btn {
  background-color: #F18F01;
  color: #fff;
  padding: 10px 25px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 30px;
  margin-left: auto;
  font-family: var(--title-font) !important;
  font-weight: 500;
  letter-spacing: 0px;
}

.topic-title-btn-mobile {
  display: none;
  font-family: var(--title-font) !important;
  font-weight: 500;
  letter-spacing: 0px;
}

.topic-levels-card-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}

.topic-levels-card {
  height: 190px;
  width: 200px;
  border-radius: 13px;
  margin-right: 35px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;
}

.topic-levels-card svg {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.topic-levels-card p {
  font-size: 20px;
  padding: 10px 15px;
  position: absolute;
  z-index: 9;
  left: 0px;
  right: 0px;
}

// .topic-review-section {
//   margin-top: 20px;
// }

.topic-review-title h2 {
  font-size: 30px;
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  letter-spacing: 0px;
  color: #27345E;
}

.topic-review-title p {
  font-size: 18px;
}

.topic-review-questions-list {
  // cursor: pointer;
  margin-top: 20px;
}

.topic-review-question {
  box-shadow: var(--global-box-shadow);
  display: flex;
  // padding: 0px 20px 0px 10px;
  padding: 5px 10px;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 20px;

  .first-element {
    width: 90%;
  }
}

.topic-review-question-right {
  margin-left: auto;
}

.disable-question {
  .first-element {
    opacity: 0.3;
  }
}

.topic-review-question p {
  padding: 20px 15px;
  font-size: 18px;
  display: inline-block;
}

.topic-review-loading-gif {
  width: auto;
  height: 45px;
  margin-left: auto;
}

.topic-review-question {
  .disable {
    opacity: 0.3;
  }
}

.gen-topics-card-color-bar {
  background: #fdf233;
  width: 100%;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  bottom: 0%;
}

// .topic-review-question span{
//     background-color: #fff;
//     padding: 20px 15px;
//     border-radius: 20px;
//     font-size: 18px;
// }

.wave-pattern {
  margin-top: 200px;
  height: 1000px;
}

.wave-card {
  background-color: #fff;
  height: 200px;
  width: 250px;
}

.topic-levels-lock {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  height: auto;
  margin-left: -14px;
  margin-top: -16px;
}

.topic-levels-lock-active {
  display: block;
  opacity: 1;
}

.topic-levels-lock-inactive {
  display: none;
}

.topic-levels-card-content-active {
  cursor: default;
  opacity: 1;
}

.topic-levels-card-content-inactive {
  cursor: pointer;
  opacity: 0.5;
}

.topic-review-question-popup-container {
  position: fixed;
  background-color: #27345eba;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 1111111;
  display: none;
}

.topic-review-question-popup-content {
  padding: 50px 60px;
  width: 600px;
  margin-left: auto;
  background-color: #fff;
  height: 100vh;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
}

.topic-review-question-popup-title {
  font-size: 20px;
  font-family: var(--sub-title-font) !important;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #201E1F;
  line-height: 25px;
}

.topic-review-question-popup-explanation {
  font-size: 16px;
  color: #201E1F;
  margin-top: 15px;
  font-family: var(--content-font) !important;
  font-weight: 400;
  height: 60vh;
  overflow-y: auto;
}

.topic-review-question-popup-content img {
  height: 30px;
  width: auto;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
}

.PushMode {
  border-radius: 20px;
  padding: 10px 15px;
  background-color: #F18F01;
  cursor: pointer;
  width: fit-content;
  font-size: 18px;
  font-weight: 300;
  color: white;
  margin-left: 20px;
}

.topic-levels-card-list-responsive-inactive {
  display: block;
}

.topic-levels-card-list-responsive-active {
  display: none;
}

// .swiper {
//   width: 100%;
//   height: 260px;
//   margin-left: auto;
//   margin-right: auto;
// }

.home-new-file-upload-container {
  box-shadow: var(--global-box-shadow);
  background-color: #fff;
  width: 400px;
  min-height: 200px;
  margin-top: 20px;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 25px;
}

.home-new-file-upload-container p {
  font-size: 22px;
  text-align: left;
}

.home_explanation_file_upload_parent {
  width: 100%;
  // width: fit-content;
  // margin: auto;
}



.home-new-file-upload-container button {
  margin-top: 35px;
  background-color: #F18F01;
  padding: 10px 20px;
  font-size: 25px;
  color: #fff;
  border-radius: 20px;
}

.home-new-file-upload-container button>img {
  width: auto;
  height: 25px;
  margin-right: 15px;
}

.home-new-topic-gen-container {
  box-shadow: var(--global-box-shadow);
  background-color: #fff;
  width: 450px;
  min-height: 200px;
  margin-top: 20px;
  margin-bottom: 25px;
  border-radius: 20px;
  padding: 20px;
}

.home-new-topic-gen-container img {
  width: auto;
  height: 45px;
}

.home-new-topic-gen-container p {
  font-size: 22px;
  margin-top: 25px;
}





@media only screen and (max-width: 1450px) and (min-width: 1000px) {
  .topic-container {
    margin-left: 220px;
    justify-content: flex-start;
  }

  .topic-wrapper {
    width: 70vw;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 500px) {
  .topic-container {
    margin-left: 0px;
    justify-content: center;
  }

  .topic-wrapper {
    width: 85vw;
  }
}

@media screen and (max-width: 500px) {
  .topic-wrapper {
    width: 90vw;
    margin-top: 25px;
  }

  .topic-title h2 {
    font-size: 30px;
  }

  .topic-title p {
    font-size: 16px;
  }

  .topic-review-title>h2 {
    font-size: 26px;
  }

  .topic-review-title p {
    font-size: 16px;
  }

  .topic-review-question {
    width: 100%;
    position: relative;
  }

  .disable-question img {
    // padding: 15px 35px;
    position: absolute;
    left: 45%;
    top: 22%;
  }

  .PushMode {
    padding: 8px 12px;
    font-size: 14px;
  }

  .topic-review-question p {
    padding: 10px 10px;
    font-size: 17px;
  }

  .topic-review-question-popup-content {
    width: 85%;
    padding: 10px 15px;
  }

  .topic-review-question-popup-title {
    margin-top: 15px;
    font-size: 20px;
  }

  .topic-review-question-popup-explanation {
    font-size: 16px;
    margin-top: 15px;
  }

  .topic-review-question-popup-content img {
    display: none;
  }

  .topic-levels-card-list-responsive-inactive {
    display: none;
  }

  .topic-levels-card-list-responsive-active {
    display: block;
  }

  .topic-review-section {
    margin-top: 15px;
  }

  .topic-levels-card {
    width: 100%;
    height: 170px;
    margin-right: 0px;
  }

  .topic-title-btn {
    display: none;
  }

  .topic-title-btn-mobile {
    display: block;
    background-color: rgba(241, 145, 1, 0);
    color: #F18F01;
    padding: 0px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 0px;
    position: absolute;
    font-size: 22px !important;
    right: 20px;
    top: 10px;
    z-index: 1111;
  }

  .topic-review-loading-gif {
    height: 35px;
  }

  .home-new-topic-gen-container {
    width: 100%;
  }

  .home-new-topic-gen-container p {
    font-size: 18px;
  }

  .home-new-file-upload-container {
    width: 100%;
  }

  .home-new-file-upload-container p {
    font-size: 18px;
  }

}

@media screen and (max-width: 500px) {
  .topic-levels-card {
    height: 100%;
  }
}

@media screen and (max-width: 400px) {
  .topic-review-title>h2 {
    font-size: 22px;
  }
}


// swipper for mobile
// .swiper {
//   width: 100%;
//   height: 430px !important;
//   margin-left: auto;
//   margin-right: auto;
// }
// .swiper-wrapper {
//   height: 360px !important;
// }
// .swiper-slide {
//   text-align: center;
//   font-size: 18px;
//   height: calc((100% - 30px) / 2) !important;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .mobile-main-swiper{
//   width: 100%;
// }