.card-wrapper {
    position: relative;
    width: 90%;
    background-color: #fff;
    height: 190px;
    border-radius: 5px;
    padding: 18px;
}

.card-container {
    /* width: 250px; */
    /* height: 100%; */
    width: 90%;
    text-align: left;
    /* position: relative; */
}

.card-lock {
    height: 28px;
    width: auto;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.card-title {
    font-size: 18px;
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
    color: #27345E;
    min-height: 45px;
    line-height: 22px;
}

.card-description {
    font-size: 14px;
    font-family: var(--content-font) !important;
    font-weight: 400;
    color: #27345E;
    margin-top: 12px;
    min-height: 23px;
}

.card-button {
    font-size: 17px;
    font-family: var(--title-font) !important;
    color: #F18F01;
    font-weight: 600;
    margin-top: 15px;
    cursor: pointer;
}

.card-progress-bar {
    position: absolute;
    background-color: #FFDB5E;
    width: 100%;
    height: 30px;
    bottom: 0px;
    left: 0px;
    border-radius: 5px;
}

.concepts-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
}

.concepts-title>p {
    font-family: var(--sub-title-font) !important;
    color: #27345E;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0px;
}

.concepts-title>strong {
    font-family: var(--sub-title-font) !important;
    color: #F18F01;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0px;
    cursor: pointer;
    padding-right: 55px;
}

.weather-container {
    display: flex;
    gap: 15px;
    align-items: center;
}

.weather-container p {
    font-size: 20px;
    color: #69728f;
    font-family: var(--light-font);
}

.home-banner {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px 30px;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    letter-spacing: 0.1px;
}

.home-banner-left {
    width: 270px;
}

.home-banner-left h2 {
    font-family: var(--sub-title-font) !important;
    color: #27345E;
    font-size: 30px;
    font-weight: 500;
    /* margin-bottom: 10px; */
}

.home-banner-left p {
    font-family: var(--content-font) !important;
    color: #000;
    font-size: 14px;
    font-weight: 400;
}

.home-banner-right>button {
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0px;
    background-color: #F18F01;
    color: #fff;
    padding: 10px 34px;
    cursor: pointer;
    border-radius: 7px;
}

.concepts-card-list {
    margin-top: 0px;
    width: 100%;
}

.folder-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 13px;
}

.weather-image {
    width: 35px;
    height: 35px;
}

.user-message {
    font-size: 30px;
    letter-spacing: 1px;
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
    color: #000;
    letter-spacing: 0px;
    line-height: 37px;
}

.exam-space-list {
    position: relative;
}

.exam-space-name-list {
    position: absolute;
    width: 100%;
    border-radius: 5px;
    border: 1px solid white;
    margin-top: 8px;
}

.exam-space-name {
    cursor: pointer;
    width: 100%;
    background-color: white;
    text-align: center;
    font-size: 18px;
    padding: 3px 0px;
}

.exam-space-topic {
    font-size: 20px;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    min-width: 230px;
    align-items: center;
    background-color: white;
    padding: 4px 10px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: var(--global-box-shadow);
}

.exam-space-topic p {
    color: #000;
    font-family: var(--sub-title-font);
    font-weight: 500;
}
.exam-space-topic svg{
    height: 20px;
    width:auto;
}
.max-icon-mobile {
    display: none;
}

.folder-list-menu-display {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
}

.content-card {
    width: 300px;
    background-color: #fff;
    height: 230px;
    border-radius: 5px;
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--global-box-shadow);
}

.content-card-chart {
    width: 340px;
    background-color: #fff;
    height: 230px;
    border-radius: 5px;
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--global-box-shadow);
}

.content-card-title {
    font-size: 25px;
    font-family: var(--sub-title-font);
    font-weight: 500;
    color: #27345E;
    padding: 18px 18px 0px 18px;
    height: 95px;
}

.content-card-pages {
    font-size: 18px;
    color: #69728f;
    padding: 0px 18px 18px 18px;
    margin-top: 0px;
    font-family: var(--light-font);
}

.content-progress-bar {
    background-color: #C4D7B2;
    width: 100%;
    height: 20px;
    /* border-radius: 5px; */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.content-card-continue {
    width: 100%;
    cursor: pointer;
    font-size: 22px;
    padding: 12px 0px;
    text-align: center;
    color: #27345E;
    font-family: var(--sub-title-font);
    font-weight: 500;
    margin-top: auto;
}
.content-card-continue:hover{
    background-color: #F7F7F7;
}
.content-card-continue-hr-line {
    display: none;
}

.chart-menu-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 10px 20px;

}

.chart-menu-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.chart-menu-color {
    width: 15px;
    height: 15px;
    background-color: #F18F01;
    border-radius: 50%;
}

.chart-menu-text {
    font-size: 18px;
    font-family: var(--light-font);
    color: #69728f;

}

.chart-menu-questions {
    font-size: 22px;
    padding: 12px 0px;
    text-align: center;
    color: #27345E;
    font-family: var(--sub-title-font);
    font-weight: 500;
    cursor: pointer;
    margin-top: auto;
}
.chart-menu-questions:hover{
    background-color: #F7F7F7;
}

.chart-menu-free-user {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.chart-menu-free-user p {
    margin: 0px;
}

.chart-menu-free-user p {
    margin: 0px;
}

.chart-menu-free-user img {
    width: 22px;
    height: 22px;
}
.chart-menu-free-user:hover{
    background-color: #F7F7F7;
}

@media only screen and (max-width: 1000px) {
    .card-container {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .topic-review-title h2{
        margin-top: 25px;
    }
    .home-banner {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 15px 15px;
    }

    .home-banner-left {
        padding-right: 0px;
        width: 170px;
    }

    .home-banner-left>h2 {
        font-size: 26px;
        margin-bottom: 0px;
    }

    .home-banner-left>p {
        font-size: 12px;
        margin-top: 0px;
    }

    .home-banner-right>button {
        font-size: 14px;
        padding: 10px 10px;
        /* width: 100px; */
    }

    .card-wrapper {
        width: 220px;
    }

    .concepts-title p {
        font-size: 26px;
        letter-spacing: -0.5 px;
    }

    .concepts-title strong {
        font-size: 18px;
        padding-right: 0px;
    }

    .weather-image {
        width: 25px;
        height: 25px;
    }

    .weather-container p {
        font-size: 15px;
    }

    .folder-list-menu-display {
        flex-direction: column;
        gap: 0px;
        align-items: baseline;
        margin-bottom: 25px;
    }

    .content-card-title {
        font-size: 16px;
        height: fit-content;
    }

    .content-card-pages {
        font-size: 16px;
    }

    .content-card {
        height: fit-content;
    }

    .content-card-continue {
        font-size: 18px;
        padding: 12px;
    }

    .content-card-continue-hr-line {
        display: block;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .chart-menu-questions {
        padding-top: 12px;
        font-size: 18px;
    }

    .content-card-chart {
        height: 223px;
        margin: 0px;
    }

    .weather-container {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .content-progress-bar {
        height: 13px;
    }

    .chart-menu-text {
        font-size: 16px;
    }
    .user-message {
        font-size: 28px;
    }

}

@media screen and (max-width: 490px) {}

@media screen and (max-width: 400px) {
    .home-banner-left>h2 {
        font-size: 22px;
    }

    .concepts-title>p {
        font-size: 22px;
    }

}



/* .swiper {
    width: 100%;
    height: 430px !important;
} */
/* margin-left: auto;
margin-right: auto; */
/* .swiper-wrapper {
    height: 360px !important;
} */
/* .swiper-slide {
    width: 120px !important;
} */
/* text-align: center;
font-size: 18px;
height: calc((100% - 30px) / 2) !important;
display: flex;
justify-content: center;
align-items: center; */