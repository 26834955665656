.container{
    display: flex;
    justify-content: center;
    width: 100vw;
    // font-family: "product-sans-regular-400", sans-serif;
}

.content{
    background-color: #fff;
    padding: 20px 35px;
    border-radius: 20px;
    margin-top: 100px;
    min-height: 400px;
    width: 400px;
    text-align: center;
}
.content h2{
    font-size: 35px;
    margin-bottom: 30px;
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
}
.password-box{
    margin-bottom: 15px;
}
.password-box p{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
    text-align: left;
    font-family: var(--content-font) !important;
    font-weight: 400;
}
.password-box input{
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 30px;
    font-size: 18px;
}
.content > button{
    background-color: #F18F01;
    font-size: 20px;
    padding: 10px 30px;
    border-radius: 8px;
    margin-top: 20px;
    font-family: var(--sub-title-font) !important;
    color: #fff;
    font-weight: 500;
    // width: 100%;
}
.link{
    font-size: 15px;
    text-align: left;
    font-family: var(--content-font) !important;
    font-weight: 400;
}
.link a{
    color: #F18F01;
    font-family: var(--content-font) !important;
    font-weight: 400;
}