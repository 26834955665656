.main_message {
    background-color: #f0f6f5;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;

    .message_content {
        width: 650px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        padding-top: 100px;
        margin-top: 120px;
        padding-bottom: 50px;
        position: relative;
        @media(max-width:700px) {
           width: 90%;
        }
        .close {
            font-size: 25px;
            color: #4D4D4D;
            position: absolute;
            top: 25px;
            cursor: pointer;
            right: 25px;
        }

        .message_material {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .icon {
                font-size: 80px;
                color: #201E1F;
                
            }

            .title {
                
                font-size: 32px;
                color: #201E1F;
                max-width: 300px;
                opacity: 0.9;
                text-align: center;
                @media(max-width:700px) {
                   font-size: 26px;
                }
            }

            .para {
                max-width: 195px;
                opacity: 0.9;
                font-weight: 100;
                padding-top: 20px;
                
                font-size: 18px;
                color: #201E1F;
                text-align: center;
                @media(max-width:700px) {
                    font-size: 16px;
                 }
            }
        }

    }

}