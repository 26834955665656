.main_nav,
.main_nav_pad {
  font-family: var(--content-font) !important;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 30px 60px;
  z-index: 111;
  width: 100%;
  transition: 0.3s ease-in-out;

  .top_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    .ham_burger {
      // background-color: #201E1F;
      color: #201E1F;
      position: absolute;
      left: 20px;
      margin-top: 5px;
      cursor: pointer;
      // width: 30px;
      // height: 30px;
      // border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .first_burger {
      // background-color: #201E1F;
      color: #201E1F;
      position: absolute;
      left: 20px;
      margin-top: 5px;
      cursor: pointer;
      // width: 30px;
      // height: 30px;
      // border-radius: 50%;
      display: none;
    }

    .logo_text {
      font-size: 32px;
      color: #201E1F;

      @media (max-width: 500px) {
        font-size: 32px;
      }
    }
  }

  .btn_sec {
    display: flex;
    align-items: center;
    gap: 30px;
    @media (max-width: 750px) {
      gap: 20px;
    }
    button {
      padding-left: 30px;
      padding-right: 30px;
      transition: 0.3s ease-in-out;

      @media (max-width: 750px) {
        line-height: unset !important;
        font-size: 18px !important;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .text {
      font-size: 18px;
      color: #201E1F;
      font-weight: 100;
      cursor: pointer;
    }

    .text_2 {
      font-size: 18px;
      color: #201E1F;
      font-weight: 100;
      cursor: pointer;
      background-color: white;
      width: 100px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      @media (max-width: 750px) {
        width: unset;
        padding-left: 25px;
        padding-right: 25px;
      }
      @media (max-width: 500px) {
        font-size: 14px;
      }
    }
  }
}

.main_nav_pad {
  padding: 30px 60px 30px 160px;

  @media (max-width: 1350px) {
    padding: 30px 20px 30px 20px;
  }
}

.main_nav {
  padding: 30px 60px 30px 60px;
  @media (max-width: 750px) {
    padding: 30px 20px 30px 70px;
  }
  @media (max-width: 500px) {
    padding: 30px 20px 30px 20px;
  }
}
.ant-drawer {
  z-index: 1111111111111111;
}
.ant-drawer-left {
  .ant-drawer-header {
    display: none !important;
  }

  .ant-drawer-content-wrapper {
    height: unset !important;
    width: 210px !important;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    // @media (max-width: 400px) {
    // 	width: 210px !important;
    // }
  }

  .ant-drawer-content {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    // @media (max-width: 400px) {
    //   border-radius: unset !important;
    // }
  }

  .ant-drawer-body {
    padding: 24px 10px 24px 0px;

    .ham_content {
      height: 100%;
      font-family: var(--content-font) !important;
      font-weight: 400;
    //   height: 100vh;

      .top_sec {
        padding-left: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo_text {
          font-size: 32px;
          color: #201E1F;
        }

        .icon {
          font-size: 30px;
          cursor: pointer;
          color: #4d4d4d;
        }
      }

      .menu_main {
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        padding-left: 20px;

        .menu {
          font-size: 18px;
          font-weight: 700;
          transition: 0.3s ease-in-out;
          color: #201E1F;
          border-radius: 10px;
          padding: 15px 0px;
          width: 100%;
          cursor: pointer;

          &:hover {
            background-color: #F0F5FF;
          }

          span {
            padding-left: 20px;
            font-family: var(--content-font) !important;
            font-weight: 600;
            letter-spacing: 0px;
          }
        }

        .bottom_sec {
          padding-top: 10px;
          display: flex;
          gap: 2rem;
          flex-direction: column;
        }
      }

      .last_sec {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 30px;
        padding-left: 40px;

        .actions {
          cursor: pointer;

          font-size: 18px;
          font-weight: 300;
          color: #201E1F;
        }
      }

      .PushModeParent {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 30px;
        padding-left: 15px;

        .PushMode {
          border-radius: 20px;
          padding: 10px 15px;
          background-color: #F18F01;
          cursor: pointer;
          width: fit-content;
          font-size: 18px;
          font-weight: 300;
          color: white;
        }
      }

      .activeuser {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 30px;
        padding-left: 40px;
        font-size: 18px;
        font-weight: 300;

        .active {
          color: #f19208;
        }
      }
    }
  }
}
.navbar-wrapper-new{
  width: 1100px;
  margin: 0px auto;
  @media (max-width: 500px) {
    width: 100%;
  }
}