// questio counter bar
.question-counter-container{
    background-color: #fff;
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10;

}
.question-counter-content{
    position: relative;
}
.question-counter-content > p{
    font-family: var(--sub-title-font) !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    position: relative;
}
.question-counter-content > p > span{
    font-family: var(--sub-title-font) !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #F18F01;
    cursor: pointer;
}
.question-counter-content p img{
    width: auto;
    height: 12px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: -15px;
}
.question-counter-info-text{
    display: none;
    background-color: #fff;
    font-family: var(--content-font) !important;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    position: absolute;
    top: 40px;
    width: 300px;
    padding: 10px;
    border-radius: 3px;
    right: 0px;
    z-index: 50;
}
.question-counter-info-text strong{
    font-family: var(--content-font) !important;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    text-decoration: underline;
    cursor: pointer;
}
.pushmode-end-text1{
    padding: 5px 0px;
    width: 100vw;
    background-color: #fff;
    text-align: center;
    font-family: var(--sub-title-font);
    color: #27345E;
    font-size: 16px;
}
.pushmode-end-text2{
    font-family: var(--sub-title-font);
    color: #F18F01;
    font-size: 16px;
}

@media screen and (max-width: 500px){
    .question-counter-container{
        padding: 10px 10px;
    }
    .question-counter-content > p{
        font-size: 12px;
    }
    .question-counter-content > p > span{
        font-size: 12px;
    }
    .pushmode-end-text1{
        font-size: 14px;
    }
    .pushmode-end-text2{
        font-size: 14px;
    }
}