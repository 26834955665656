.main_mcq {
  background-color: #f0f6f5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 750px) {
    height: unset;
    padding-bottom: 40px;
  }

  .mixer_btn {
    display: flex;
    flex-direction: column;
    padding: 13px 20px;
    background-color: white !important;
    border-top-left-radius: 40px;
    cursor: pointer;
    border-bottom-left-radius: 40px;
    position: absolute;
    right: 0;
    top: 130px;
    font-size: 20px;

    @media (max-width: 750px) {
      top: 130px;
    }

    // @media(max-width:850px) {
    //     top: 15px;
    //     z-index: 11111111111111111111111;
    // }
  }

  .help_btn {
    display: flex;
    flex-direction: column;
    padding: 13px 20px;
    background-color: white !important;
    border-top-left-radius: 40px;
    cursor: pointer;
    border-bottom-left-radius: 40px;
    position: absolute;
    right: 0;
    font-size: 20px;
    top: 190px;
    // @media(max-width:850px) {
    //     top: 55px;
    //     z-index: 11111111111111111111111;
    //     background-color: unset !important;
    // }
  }

  .mcq_title {
    padding-top: 120px;
    font-family: "PT Sans", sans-serif;
    font-size: 32px;
    color: #201E1F;

    @media (max-width: 750px) {
      font-size: 21px;
    }

    @media (max-width: 550px) {
      padding-left: 50px;
      padding-right: 50px;
    }

    .mcq_title {
        padding-top: 120px;
        
        font-size: 32px;
        color: #201E1F;

        @media(max-width:750px) {
            font-size: 21px;
            padding-right: 55px !important;

        }

        @media(max-width:550px) {
            padding-left: 15px;
            padding-right: 55px !important;

        }

        @media(max-width:382px) {
            max-width: 75%;

        }
    }
  }

    .main_option {
        display: flex;
        width: 521px;
        gap: 1.3rem;
        flex-wrap: wrap;
        padding-top: 60px;

        @media(max-width:550px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: unset;
            padding-top: 30px;
        }

        .card {
            cursor: pointer;
            color: #4D4D4D;
            
            font-size: 21px;
            padding: 15px 15px 15px 25px;
            width: 300px;
            background-color: white;
            border-radius: 10px;

            @media(max-width:750px) {
                font-size: 18px;
            }

        }
  }
}

.main_qeust {
  height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #f0f6f5;
  position: relative;

  .mixer_btn {
    display: flex;
    flex-direction: column;
    padding: 13px 20px;
    background-color: white !important;
    border-top-left-radius: 40px;
    cursor: pointer;
    border-bottom-left-radius: 40px;
    position: absolute;
    right: 0;
    top: 130px;
    font-size: 20px;

    // @media(max-width:850px) {
    //     top: 15px;
    //     z-index: 11111111111111111111111;
    //     background-color: unset !important;
    // }
  }

  .help_btn {
    display: flex;
    flex-direction: column;
    padding: 13px 20px;
    background-color: white !important;
    border-top-left-radius: 40px;
    cursor: pointer;
    border-bottom-left-radius: 40px;
    position: absolute;
    right: 0;
    font-size: 20px;
    top: 190px;
    // @media(max-width:850px) {
    //     top: 55px;
    //     z-index: 11111111111111111111111;
    //     background-color: unset !important;
    // }
  }

  .question_content {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f0f6f5;
    gap: 30px;
    padding-top: 140px;

    @media (max-width: 768px) {
      padding-right: 60px;
      padding-left: 60px;
    }

    .card {
      padding: 50px 80px;
      background-color: white;
      border-radius: 30px;

      @media (max-width: 850px) {
        width: 90%;
        padding: 30px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .title {
        color: #201E1F;
        font-size: 30px;
        max-width: 530px;
        text-align: center;
        font-family: "PT Sans", sans-serif;

        @media (max-width: 850px) {
          font-size: 21px;
          line-height: unset !important;
        }
      }

    }
    
    
    .question_content_footer {
        display: flex;
        align-items: center;
        flex-direction: row;
        background-color: #F0F6F5;
        gap: 30px;
        padding-top: 140px;

        @media(max-width:550px) {
          padding-top: 90px;
          flex-direction: column;
        }

        .card {
            padding: 50px 80px;
            background-color: white;
            border-radius: 30px;

            @media(max-width:850px) {
                width: 90%;
                padding: 30px 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .title {
                color: #201E1F;
                font-size: 30px;
                max-width: 530px;
                text-align: center;
                

                @media(max-width:850px) {
                    font-size: 21px;
                    line-height: unset !important;
                }

            }

        }
        .button {
          background-color: #e2521e;
          padding: 12px;
          font-size: 21px;
        }
        .may {
            
            font-size: 18px;
            color: #201E1F;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
        }

    }
    
  }
}


.progress-bar-container{
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  visibility: hidden;
  bottom: 50px;
  left: calc(66% - 500px);
  width: 500px;
  background-color: #fff;
  border-bottom-right-radius: 10px 10px;
  border-bottom-left-radius: 10px 10px;
}
.progress-bar{
  margin-top: -10px;
  background-color: #c1ff72;
  height: 10px;
  width: 100%;
  border-top-right-radius: 100px 100px;
  border-top-left-radius: 100px 100px;
  transition: width 3s;
}
.progress-bar-content{
  text-align: center;
  padding: 0px 15px 10px 15px;
}
.progress-bar-content h3{
  margin-top: 15px;
}
.progress-bar-content p{
  margin-top: 10px;
  font-size: 15px;
}