.container{
    width: 100vw;
    height: 100%;
    background-color: #f0f5ff;
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 999;
}
.content{
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;
}
.title{
    font-family: var(--title-font) !important;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 30px;
    margin-top: 100px;
    color: #003C34;
}
.description{
    font-family: var(--content-font) !important;
    font-weight: 500;
    letter-spacing: -0.5px;
    font-size: 20px;
    margin-top: 20px;
    color: #003C34;
}
.concept{
    margin-top: 7px;
    color: #003C34;
}
.question-box-container{
    margin-top: 50px;
}
.question-answer{
    display: none;
}
.question-button{
    cursor: pointer;
}
.question-box-container{
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
}
.green-bar{
    height: 30px;
    width: 100%;
    background-color: #C1FF72;
    position: absolute;
    top: 0px;
    left: 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.white-bar{
    height: 20px;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 12px;
    left: 0px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}
.question-box{
    background-color: #fff;
    padding: 20px;
    min-height: 190px;
    width: 250px;
    border-radius: 10px;
    text-align: left;
    position: relative;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding-top: 30px;
}
.question-title{
    font-family: var(--sub-title-font) !important;
    font-weight: 400;
    letter-spacing: 0px;
    font-size: 18px;
    min-height: 90px;
    color: #003C34;
}
.question-button{
    font-family: var(--content-font) !important;
    font-weight: 400;
    letter-spacing: 0px;
    font-size: 14px;
    color: #003C34;
}
.question-answer{
    font-family: var(--sub-title-font) !important;
    font-weight: 400;
    letter-spacing: 0px;
    font-size: 14px;
    color: #4D4D4D;
}
/* .close-icon{
    position: absolute;
    top: 10px;
    right: 10px;
    height: 20px;
    width: auto;
    cursor: pointer;
} */

.close-button{
    background-color: #F18F01;
    padding: 15px 45px;
    margin-top: 10px;
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    font-family: var(--title-font) !important;
    font-weight: 600;
    letter-spacing: 0px;
    font-size: 21px;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .container{
        position: absolute;
        height: 200vh;
        top:0px;
    }
    .content{
        width: 85vw;
    }
    .close-button{
        margin-top: 10px;
    }
    .question-box{
        width: 100%;
    }
    .title{
        font-size: 28px;
    }
    .description{
        font-size: 17px;
    }
    .title{
        margin-top: 50px;
    }
}