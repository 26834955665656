.push-mode-container{
    display: flex;
    justify-content: center;
    color: #201E1F;
    // font-family: "product-sans-regular-400", sans-serif;
}
.push-mode-content{
    width: 1000px;
    margin-top: 20px;
}
.title-section h3{
    font-size: 30px;
    letter-spacing: 1.5px;
}
.title-section p{
    font-size: 18px;
    width: 400px;
    margin-top: 20px;
}
.plans-section{
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 25px;
}
// subscription type toggle btn
.toggle-btn-container{
    margin-bottom: 40px;
    max-width: 281px;
}
.toggle-btn-content{
    border: 2px solid #d6dae3;
    border-radius: 30px;
    display: flex;
    align-items: center;
}
.toggle-btn{
    font-family: var(--sub-title-font) !important;
    font-weight: 500;
    font-size: 18px;
    width: 140px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.toggle-btn span{
    font-family: var(--title-font) !important;
    font-weight: 700;
    font-size: 14px;
    background-color: #7ED957;
    color: #fff;
    padding: 1px 5px;
    border-radius: 30px;
    margin-left: 5px;
}
.toggle-btn-selected{
    font-family: var(--title-font) !important;
    font-weight: 700;
    background-color: #fff;
    border: 2px solid #989da4;
    border-radius: 30px;
}
// offer banner
.offer-banner-green{
    background-color: #7ED957;
    color: #fff;
    font-family: var(--title-font) !important;
    font-weight: 500;
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-align: center;
    padding-bottom: 15px;
}
.offer-banner-white{
    background-color: #fff;
    position: absolute;
    top: 22px;
    left: 0px;
    width: 100%;
    height: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

// plan card
.plan-card{
    box-shadow: var(--global-box-shadow);
    position: relative;
    background-color: #fff;
    padding: 40px 50px;
    border-radius: 20px;
    margin-bottom: 40px;
    width: 470px;
    margin-top: 10px;
}
.plan-card h2{
    font-family: var(--title-font) !important;
    font-weight: 500;
    font-size: 18px;
    font-size: 38px;
}
.plan-card h2 span{
    font-family: var(--content-font) !important;
    font-weight: 400;
    font-size: 20px;
}
.plan-card p{
    font-family: var(--content-font) !important;
    font-weight: 400;
    font-size: 18px;
}
.plan-card button{
    background-color: #f18f00;
    color: #fff;
    font-size: 22px;
    font-family: var(--title-font) !important;
    font-weight: 600;
    padding: 17px 40px;
    border-radius: 7px;
    margin-top: 25px;
}
.feedback-card{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.feedback-card .img-container{
    width: 115px;
    height: 140px;
    border-radius: 15px;
    background-color: #073f38;
    background-repeat: no-repeat;
    background-position: center;
}
.feedback-card .feedback-card-text{
    margin-left: 30px;
}
.feedback-card .feedback-card-text p{
    font-size: 18px;
}

.plans-section-right{
    position: relative;
}
.plans-section-right .card-2{
    position: absolute;
    left: 165px;
    width: 320px;
}
.push-mode-bullet-container{
    margin-top: 10px;
    margin-left: 10px;
}
.push-mode-bullet{
    display: flex;
    align-items: center;
}
.push-mode-bullet img{
    width: auto;
    height: 22px;
}
.push-mode-bullet p{
    font-family: var(--content-font) !important;
    font-weight: 400;
    font-size: 18px;
    margin-top: 5px;
    margin-left: 10px;
}
.plan-disclaimer{
    margin-top: 30px;
}
.plan-disclaimer h6{
    font-family: var(--title-font) !important;
    font-weight: 500;
    font-size: 20px;
}
.plan-disclaimer p{
    margin-top: 10px;
    font-family: var(--content-font) !important;
    font-weight: 500;
    font-size: 14px;
}


// ==================carousel=====================
.pushmode-user-review-carousel-container{
    display: none;
    width: 100%;
}
.pushmode-carousel-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pushmode-carousel-card > article{
    width: 100px;
    height: 130px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: center;
}
.pushmode-carousel-card-text{
    margin-left: 5px;
    width: 210px;
}
.pushmode-carousel-card-text p{
    font-size: 16px;
}
.pushmode-carousel-card-text h6{
    font-size: 16px;
    margin-top: 10px;
}

.pushmode-loading-container{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
}
.pushmode-loading-content{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f5ffe8;
}
.pushmode-loading-content img{
    height: 100px;
    width: auto;
}
.pushmode-loading-content p{
    font-size: 20px;
    font-family: var(--content-font) !important;
    font-weight: 400;
}
.pushmode-popup-backdrop{
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #f0f5ffce;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
}
.pushmode-popup-content{
    width: 400px;
    // height: 300px;
    background-color: #fff;
    padding: 30px;
}
.pushmode-popup-content > h3{
    font-family: var(--title-font) !important;
    font-size: 25px;
    font-weight: 500;
    color: #000;
    text-align: center;
    margin-bottom: 20px;
}
.pushmode-stripe-btn{
    border: 1px solid #555;
    border-radius: 5px;
    padding: 0px 20px;
    margin-bottom: 20px;
}
.pushmode-stripe-btn:hover{
    background-color: #f2f2f2;
    cursor: pointer;
}

@media only screen and (max-width: 1450px) and (min-width: 1000px){
    .push-mode-container{
        justify-content: flex-start;
        margin-left: 220px;
    }
    .push-mode-content{
        width: 70vw;
    }
}
@media only screen and (max-width: 1200px) and (min-width: 500px){
    .plans-section{
        flex-direction: column;
    }
    .plans-section-right-review{
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1000px) and (min-width: 500px){
    .push-mode-container{
        justify-content: center;
        margin-left: 0px;
    }
    .push-mode-content{
        width: 85vw;
    }
}

@media screen and (max-width: 500px){
    .push-mode-content{
        width: 85vw;
    }
    .plans-section{
        flex-direction: column;
    }
    .title-section > h3{
        font-size: 30px;
    }
    .title-section p{
        width: 100%;
    }
    .plan-card{
        width: 100%;
    }
    .plan-card h2{
        font-size: 35px;
    }
    .plan-card button{
        font-size: 19px;
        padding: 20px 40px;
    }
    .plans-section-right{
        display: none;
    }
    .pushmode-user-review-carousel-container{
        display: block;
    }
    .plans-section-right-review{
        margin-left: 0px;
    }
    .plan-card{
        padding: 30px;
        padding-top: 35px;
    }
}

@media screen and (max-width: 450px){
    .plan-card{
        padding: 20px;
        padding-top: 35px;
    }
    .plan-card > p{
        font-size: 16px;
    }
    .plan-disclaimer > h6{
        font-size: 16px;
    }
    .plan-disclaimer > p{
        font-size: 13px;
    }
}