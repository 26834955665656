.loading-gif-container{
    position: fixed;
    top: 0px;
    left: 0px;
}
.loading-gif-content{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f5ff;
}
.loading-gif-content img{
    height: 100px;
    width: auto;
}

@media screen and (max-width: 400px) {
    .loading-gif-content {
        .progress-bar {
        width: 60px;
        height: 60px;
        }
    }
}