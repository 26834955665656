.content{
    width: 980px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 20px;
}
.content img{
    width: auto;
    height: 20px;
    cursor: pointer;
}
.content > div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.content > div > p{
    background-color: #fff;
    padding: 8px 25px;
    border-radius: 20px;
    font-size: 18px;
    font-family: var(--content-font) !important;
    font-weight: 400;
    cursor: pointer;
    margin-top: 0px;
}

@media screen and (max-width: 1000px) {
    .content{
        width: 100vw;
    }
}