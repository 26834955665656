

.main_privacy {
    padding: 100px 150px 50px 150px;
    @media(max-width:990px){
    padding: 80px 50px 50px 50px;

}
    @media(max-width:400px){
    padding: 80px 30px 50px 30px;

}
    .privacy_text {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .heading {
            
            font-size: 25px;
            color: #201E1F;
            font-weight: 600;
            padding-top: 20px;
            @media(max-width:600px){
            font-size: 20px;
            }
        }

        .para {
            
            font-size: 16px;
            color: #201E1F;
            font-weight: 300;
            @media(max-width:600px){
                font-size: 13px;
                }
        }

      


    }

    .subheading {
        
        font-size: 16px;
        color: #201E1F;
        font-weight: 600;
        @media(max-width:600px){
            font-size: 13px;
            }
    }

    .how{
        
        font-size: 16px;
        color: #201E1F;
        font-weight: 300; 
        font-style: italic; 
        @media(max-width:600px){
            font-size: 13px;
            }
    }

    .main_ul{
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        li {
            
            font-size: 16px;
            color: #201E1F;
            font-weight: 300;
            @media(max-width:600px){
                font-size: 13px;
                }
        }
    }

}