.main_mcq {
    background-color: #f0f6f5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media(max-width:750px) {
        height: unset;
        padding-bottom: 40px;
    }

    .mixer_btn {
        display: flex;
        flex-direction: column;
        padding: 13px 20px;
        background-color: white !important;
        border-top-left-radius: 40px;
        cursor: pointer;
        border-bottom-left-radius: 40px;
        position: absolute;
        right: 0;
        top: 130px;
        font-size: 20px;

        @media(max-width:750px) {
            top: 130px;
        }

        // @media(max-width:850px) {
        //     top: 15px;
        //     background-color: transparent !important;
        //     z-index: 11111111111111111111111;
        // }
    }

    .help_btn {
        display: flex;
        flex-direction: column;
        padding: 13px 20px;
        background-color: white !important;
        border-top-left-radius: 40px;
        cursor: pointer;
        border-bottom-left-radius: 40px;
        position: absolute;
        right: 0;
        font-size: 20px;
        top: 190px;
        // @media(max-width:850px) {
        //     top: 55px;
        //     z-index: 11111111111111111111111;
        //     background-color: unset !important;
        // }

        @media(max-width:750px) {
            top: 200px;
            background-color: white !important;
        }
      
    }

    .mcq_title {
        padding: 20px;
        padding-top: 120px;
        padding-right: 50px;
        
        font-size: 32px;
        line-height: 32px;
        color: #201E1F;

        @media(max-width:750px) {
            font-size: 21px;
            padding-left: 10px;
            padding-right: 10px;
        }
        @media(max-width:550px) {
           padding-left: 50px;
           padding-right: 50px;
          

        }
        @media(max-width:382px) {
          max-width: 75%;
          
        }
    }

    .main_option {
        display: flex;
        width: 521px;
        gap: 1.3rem;
        flex-wrap: wrap;
        padding-top: 60px;

        @media(max-width:550px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: unset;
            padding-top: 30px;
        }

        .card {
            cursor: pointer;
            color: #4D4D4D;
            
            font-size: 21px;
            padding: 15px 15px 15px 25px;
            max-width: 250px;
            background-color: white;
            border-radius: 10px;

            @media(max-width:750px) {
                font-size: 18px;
            }

        }


    }

}